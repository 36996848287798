angular
    .module('annexaApp')
    .factory('GlobalDataFactory',['RestService', '$q', 'Language', '$http', 'CacheFactory', '$rootScope', 'HelperService', 'CommonService', '$translate', 'tmhDynamicLocale', '$filter', function(RestService, $q, Language, $http, CacheFactory, $rootScope, HelperService, CommonService, $translate, tmhDynamicLocale, $filter) {
        CacheFactory('globalDataCache', {
            maxAge: 4 * 60 * 60 * 1000, // 4 hores
            cacheFlushInterval: 8 * 60 * 60 * 1000, // 8 hores
            deleteOnExpire: 'aggressive',
            storageMode: 'sessionStorage'
        });

        var factory = {};

        factory.documentTypes = [];
        factory.allDocumentTypes = [];
        factory.templateTypes = [];
        factory.templates = [];
        factory.archiveClassifications = [];
        factory.archiveClassificationsTree = [];
        factory.documentStatuses = [
            { id: 'COMPLETE', name: 'COMPLETE', icon: 'dot-circle-o', style: 'success', orderStatus: 6, nameLiteral: 'global.documentState.COMPLETE' },
            { id: 'NEW', name: 'NEW', icon: 'circle-o', style: 'blue', orderStatus: 1, nameLiteral: 'global.documentState.NEW' },
            { id: 'NEW_AND_ATTACHMENT', name: 'NEW_AND_ATTACHMENT', icon: 'adjust', style: 'blue', orderStatus: 2, nameLiteral: 'global.documentState.NEW_AND_ATTACHMENT' },
            { id: 'PENDING_SIGN', name: 'PENDING_SIGN', icon: 'circle', style: 'blue', orderStatus: 3, nameLiteral: 'global.documentState.PENDING_SIGN' },
            { id: 'REJECTED', name: 'REJECTED', icon: 'circle', style: 'danger', orderStatus: 5, nameLiteral: 'global.documentState.REJECTED' },
            { id: 'SIGNED', name: 'SIGNED', icon: 'circle', style: 'success', orderStatus: 4, nameLiteral: 'global.documentState.SIGNED' },
            { id: 'ARCHIVED', name: 'ARCHIVED', icon: 'circle-o', style: 'success', orderStatus: 7, nameLiteral: 'global.documentState.ARCHIVED', hide: true }
            
        ];
        factory.documentStatusesValidConfig = [
            { id: 'NEW_AND_ATTACHMENT', name: 'NEW_AND_ATTACHMENT', icon: 'adjust', style: 'blue', orderStatus: 2, nameLiteral: 'global.documentState.NEW_AND_ATTACHMENT'},
            { id: 'COMPLETE', name: 'COMPLETE', icon: 'dot-circle-o', style: 'success', orderStatus: 6, nameLiteral: 'global.documentState.COMPLETE'},
            { id: 'PENDING_SIGN', name: 'PENDING_SIGN', icon: 'circle', style: 'blue', orderStatus: 3, nameLiteral: 'global.documentState.PENDING_SIGN'},
            { id: 'SIGNED', name: 'SIGNED', icon: 'circle', style: 'success', orderStatus: 4, nameLiteral: 'global.documentState.SIGNED'}
        ];
        
        factory.documentViewModeTypes = [];
        factory.sections = [];
        factory.templateTree = [];
        factory.modules = [];
        factory.signActionTypes = [];
        factory.registerDiligenceTypes = [];
        factory.publishPlugins = [];
        factory.publishList = [];
        factory.allProfiles = [];
        factory.registerEntryOffices = [];
        factory.defaultRegisterEntryOffice = undefined;
        factory.registerEntryChannelsInput = [];
        factory.allRegisterEntryChannelsInput = [];
        factory.registerEntryChannelsOutput = [];
        factory.allRegisterEntryChannelsOutput = [];
        factory.registerEntryDocumentTypesInput = [];
        factory.registerEntryDocumentTypesOutput = [];
        factory.registerEntryClassificationsInput = [];
        factory.registerEntryClassificationsOutput = [];
        factory.familyProcedures = [];
        factory.familyProceduresTree = [];
        factory.procedures = [];
        factory.languages = [];
        factory.configuration = [];
        factory.pluginUibutton = [];
        factory.menuWithPermissions = [];
        factory.proposals = [];
        factory.footerClaims = [];
        factory.roleInteresteds = [];
        $rootScope.existsOptionsRoleInterested = false;
        factory.proposalTypes = [];
        factory.signstatestypes = [];
        factory.transactiontypes = [];
        factory.dossierTramitationTypes = [];
        factory.taskTemplates = [];
        factory.organs = [];
        factory.sessionTypes = [];
        factory.secTemplates = [];
        factory.organMemberRols = [];
        factory.meetingLocations = [];
        factory.bookTypes = [];
        factory.customFields = [];
        factory.secretaryProfile = undefined;
        factory.interventionProfile = undefined;
        factory.publictramtemplates = [];
        factory.operationTypes = [];
        factory.diligenceStampTypes = [];
        factory.relatedDossierRelations = [];
        factory.decreeDelegatedTypes = [];
        factory.userFilters = [];
		factory.userCountersConfiguration = [];
		factory.spelFunctions = [];
		factory.identificationReferenceTypes = [];
		factory.genders = [];
		factory.treatments = [];
		factory.relationshipLanguages = [];
        factory.tramitationPhase = [];
		factory.councillors = [];
		factory.councillorsTree = [];
		factory.newDocumentsCFConfiguration = {};
		factory.procedureProposalType =[];
		
        factory.thirdIdentificationDocumentTypes = [
            { id: 'DNI', language1: 'DNI', language2: 'DNI', language3: 'DNI' },
            { id: 'NIE', language1: 'NIE', language2: 'NIE', language3: 'NIE' },
            { id: 'NIF', language1: 'NIF', language2: 'NIF', language3: 'NIF' },
            { id: 'OTHER', language1: 'PASSPORT', language2: 'PASSPORT', language3: 'PASSPORT' },
            { id: 'OTHER', language1: 'OTHER', language2: 'OTHER', language3: 'OTHER' }
        ];
        factory.thirdTypes = [
            { id: 'PHISICAL', language1: 'PHISICAL', language2: 'PHISICAL', language3: 'PHISICAL' },
            { id: 'LEGAL', language1: 'LEGAL', language2: 'LEGAL', language3: 'LEGAL' },
            { id: 'PERSONWITHOUTLEGALPERSONALITY', language1: 'PERSONWITHOUTLEGALPERSONALITY', language2: 'PERSONWITHOUTLEGALPERSONALITY', language3: 'PERSONWITHOUTLEGALPERSONALITY' }
        ];
        factory.docModules = [
            { id: 'DOC', language1: 'DOC', language2: 'DOC', language3: 'DOC' },
            { id: 'EXPED', language1: 'EXPED', language2: 'EXPED', language3: 'EXPED' },
            { id: 'THIRDS', language1: 'THIRDS', language2: 'THIRDS', language3: 'THIRDS' },
            { id: 'REGENTRADA', language1: 'REGENTRADA', language2: 'REGENTRADA', language3: 'REGENTRADA' },
            { id: 'REGSALIDA', language1: 'REGSALIDA', language2: 'REGSALIDA', language3: 'REGSALIDA' },
            { id: 'SEC', language1: 'SEC', language2: 'SEC', language3: 'SEC' }
        ];
        factory.auditActions = [
            { id: 'CREATED', language1: $filter('translate')('global.enums.auditActions.created'), language2: $filter('translate')('global.enums.auditActions.created'), language3: 'CREATED', types:["DOSSIER","DOSSIER_TRANSACTION","DOCUMENT", "REGISTER_ENTRY", "DILIGENCE", "THIRD", "PROPOSAL", "DECREE", "ADDRESS"] },
            { id: 'VIEWED', language1: $filter('translate')('global.enums.auditActions.viewed'), language2: $filter('translate')('global.enums.auditActions.viewed'), language3: 'VIEWED', types:["DOCUMENT","DOSSIER","THIRD"] },
            { id: 'DOWNLOADED', language1: $filter('translate')('global.enums.auditActions.downloaded'), language2: $filter('translate')('global.enums.auditActions.downloaded'), language3: 'DOWNLOADED', types:["DOCUMENT"] },
            { id: 'MODIFIED', language1: $filter('translate')('global.enums.auditActions.modified'), language2: $filter('translate')('global.enums.auditActions.modified'), language3: 'MODIFIED', types:["DOSSIER","DOSSIER_TRANSACTION","DOCUMENT", "REGISTER_ENTRY", "DILIGENCE", "THIRD", "PROPOSAL", "DECREE", "ADDRESS"] },
            { id: 'SIGN_START', language1: $filter('translate')('global.enums.auditActions.sign_start'), language2: $filter('translate')('global.enums.auditActions.sign_start'), language3: 'SIGN_START', types:["DOCUMENT", "DECREE"] },
            { id: 'SIGNED', language1: $filter('translate')('global.enums.auditActions.signed'), language2: $filter('translate')('global.enums.auditActions.signed'), language3: 'SIGNED', types:["DOCUMENT", "DECREE"] },
            { id: 'VALIDATED', language1: $filter('translate')('global.enums.auditActions.validated'), language2: $filter('translate')('global.enums.auditActions.validated'), language3: 'VALIDATED', types:["DOCUMENT", "DECREE", "PROPOSAL"] },
            { id: 'REFUSED', language1: $filter('translate')('global.enums.auditActions.refused'), language2: $filter('translate')('global.enums.auditActions.refused'), language3: 'REFUSED', types:["DOCUMENT", "DECREE"] },
            { id: 'REMOVED', language1: $filter('translate')('global.enums.auditActions.removed'), language2: $filter('translate')('global.enums.auditActions.removed'), language3: 'REMOVED', types:["DOSSIER","DOSSIER_TRANSACTION","DOCUMENT", "REGISTER_ENTRY", "DILIGENCE", "THIRD", "ADDRESS"] },
            { id: 'SENDMAIL', language1: $filter('translate')('global.enums.auditActions.sendmail'), language2: $filter('translate')('global.enums.auditActions.sendmail'), language3: 'SENDMAIL', types:["DOCUMENT"] },
            { id: 'EXTERNAL_EDIT_DOCUMENT', language1: $filter('translate')('global.enums.auditActions.external_edit_document'), language2: $filter('translate')('global.enums.auditActions.external_edit_document'), language3: 'EXTERNAL_EDIT_DOCUMENT', types:["DOCUMENT"] },
            { id: 'ENDED', language1: $filter('translate')('global.enums.auditActions.ended'), language2: $filter('translate')('global.enums.auditActions.ended'), language3: 'ENDED', types:["DOSSIER"] },
            { id: 'COMPLETE_TRANSACTION', language1: $filter('translate')('global.enums.auditActions.complete_transaction'), language2: $filter('translate')('global.enums.auditActions.complete_transaction'), language3: 'COMPLETE_TRANSACTION', types:["DOSSIER_TRANSACTION"] },
            { id: 'GO_BACKWARDS_TRANSACTION', language1: $filter('translate')('global.enums.auditActions.go_backwards_transaction'), language2: $filter('translate')('global.enums.auditActions.go_backwards_transaction'), language3: 'GO_BACKWARDS_TRANSACTION', types:["DOSSIER_TRANSACTION"] },
            { id: 'ACCEPT', language1: $filter('translate')('global.enums.auditActions.accept'), language2: $filter('translate')('global.enums.auditActions.accept'), language3: 'ACCEPT', types:["DILIGENCE"] },
            { id: 'SENDTO', language1: $filter('translate')('global.enums.auditActions.sendto'), language2: $filter('translate')('global.enums.auditActions.sendto'), language3: 'SENDTO', types:["DILIGENCE"] },
            { id: 'REOPEN', language1: $filter('translate')('global.enums.auditActions.reopen'), language2: $filter('translate')('global.enums.auditActions.reopen'), language3: 'REOPEN', types:["DOSSIER_TRANSACTION"] },
            { id: 'AUTHENTIC_COPY', language1: $filter('translate')('global.enums.auditActions.authentic_copy'), language2: $filter('translate')('global.enums.auditActions.authentic_copy'), language3: 'AUTHENTIC_COPY', types:["DOCUMENT"] },
            { id: 'DRAFT', language1: $filter('translate')('global.enums.auditActions.draft'), language2: $filter('translate')('global.enums.auditActions.draft'), language3: 'DRAFT', types:["DOCUMENT"] },
            { id: 'DEFINITIVE', language1: $filter('translate')('global.enums.auditActions.definitive'), language2: $filter('translate')('global.enums.auditActions.definitive'), language3: 'DEFINITIVE', types:["DOCUMENT"] },
            { id: 'ENI', language1: $filter('translate')('global.enums.auditActions.eni'), language2: $filter('translate')('global.enums.auditActions.eni'), language3: 'ENI', types:["DOCUMENT"] },
            { id: 'PENDING', language1: $filter('translate')('global.enums.auditActions.pending'), language2: $filter('translate')('global.enums.auditActions.pending'), language3: 'PENDING', types:["PROPOSAL"] },
            { id: 'REVIWED', language1: $filter('translate')('global.enums.auditActions.reviwed'), language2: $filter('translate')('global.enums.auditActions.reviwed'), language3: 'REVIWED', types:["PROPOSAL"] },
            { id: 'ACCEPTED', language1: $filter('translate')('global.enums.auditActions.accepted'), language2: $filter('translate')('global.enums.auditActions.accepted'), language3: 'ACCEPTED', types:["PROPOSAL", "RIGHT_ACCESS"] },
            { id: 'CANCELLED', language1: $filter('translate')('global.enums.auditActions.cancelled'), language2: $filter('translate')('global.enums.auditActions.cancelled'), language3: 'CANCELLED', types:["PROPOSAL", "DECREE"] },
            { id: 'AMENDMENT', language1: $filter('translate')('global.enums.auditActions.amendment'), language2: $filter('translate')('global.enums.auditActions.amendment'), language3: 'AMENDMENT', types:["PROPOSAL", "DECREE"] },
            { id: 'CLOSED', language1: $filter('translate')('global.enums.auditActions.closed'), language2: $filter('translate')('global.enums.auditActions.closed'), language3: 'CLOSED', types:["DOSSIER"] },
            { id: 'TRANSFER', language1: $filter('translate')('global.enums.auditActions.transfer'), language2: $filter('translate')('global.enums.auditActions.transfer'), language3: 'TRANSFER', types:["DOSSIER"] },
            { id: 'REJECTED', language1: $filter('translate')('global.enums.auditActions.rejected'), language2: $filter('translate')('global.enums.auditActions.rejected'), language3: 'REJECTED', types:["DOSSIER"] },
            { id: 'ARCHIVED', language1: $filter('translate')('global.enums.auditActions.archived'), language2: $filter('translate')('global.enums.auditActions.archived'), language3: 'ARCHIVED', types:["DOSSIER","DOCUMENT"] },
            { id: 'ARCHIVED_DEFINITIVE', language1: $filter('translate')('global.enums.auditActions.archivedDefinitive'), language2: $filter('translate')('global.enums.auditActions.archivedDefinitive'), language3: 'ARCHIVED_DEFINITIVE', types:["DOSSIER"] },
    		{ id: 'CHANGE_DOCUMENT_TYPE', language1: $filter('translate')('global.enums.auditActions.change_document_type'), language2: $filter('translate')('global.enums.auditActions.change_document_type'), language3: 'CHANGE_DOCUMENT_TYPE', types:["DOCUMENT"] },
            { id: 'REGENERATE_DOCUMENT', language1: $filter('translate')('global.enums.auditActions.regenerate_document'), language2: $filter('translate')('global.enums.auditActions.regenerate_document'), language3: 'REGENERATE_DOCUMENT', types:["DOCUMENT"] },
            { id: 'ANNULLED', language1: 'Anul·lat', language2: 'Anulado', language3: 'Annulled', types:["DECREE"] },
			{ id: 'ACTIVED', language1: $filter('translate')('global.enums.auditActions.actived'), language2: $filter('translate')('global.enums.auditActions.actived'), language3: 'ACTIVED', types:["ADDRESS", "THIRD"] },
			{ id: 'INACTIVED', language1: $filter('translate')('global.enums.auditActions.inactived'), language2: $filter('translate')('global.enums.auditActions.inactived'), language3: 'INACTIVED', types:["ADDRESS", "THIRD"] },
			{ id: 'DENIED', language1: $filter('translate')('global.enums.auditActions.denied'), language2: $filter('translate')('global.enums.auditActions.denied'), language3: 'DENIED', types:["RIGHT_ACCESS"] },
			{ id: 'DELETE', language1: $filter('translate')('global.enums.auditActions.delete'), language2: $filter('translate')('global.enums.auditActions.delete'), language3: 'DELETE', types:["RIGHT_ACCESS"] },
			{ id: 'REVOKED', language1: $filter('translate')('global.enums.auditActions.revoke'), language2: $filter('translate')('global.enums.auditActions.revoke'), language3: 'REVOKED', types:["RIGHT_ACCESS"] },
			{ id: 'EXPIRED', language1: $filter('translate')('global.enums.auditActions.expired'), language2: $filter('translate')('global.enums.auditActions.expired'), language3: 'EXPIRED', types:["RIGHT_ACCESS"] },
			{ id: 'THIRD_SIGNED', language1: $filter('translate')('global.enums.auditActions.third_signed'), language2: $filter('translate')('global.enums.auditActions.third_signed'), language3: 'THIRD_SIGNED', types:["DOCUMENT"] }
        ];
        factory.publishAuditActions = [
            { id: 'CREATED', language1: $filter('translate')('global.enums.publishAuditActions.created'), language2: $filter('translate')('global.enums.publishAuditActions.created'), language3: 'CREATED', types:["DOCUMENT"] },
            { id: 'MODIFIED', language1: $filter('translate')('global.enums.publishAuditActions.modified'), language2: $filter('translate')('global.enums.publishAuditActions.modified'), language3: 'MODIFIED', types:["DOCUMENT"] },
            { id: 'CANCELLED', language1: $filter('translate')('global.enums.publishAuditActions.cancelled'), language2: $filter('translate')('global.enums.publishAuditActions.cancelled'), language3: 'CANCELLED', types:["DOCUMENT"] },
            { id: 'RETIRED', language1: $filter('translate')('global.enums.publishAuditActions.retired'), language2: $filter('translate')('global.enums.publishAuditActions.retired'), language3: 'RETIRED', types:["DOCUMENT"] },
            { id: 'PUBLISH', language1: $filter('translate')('global.enums.publishAuditActions.publish'), language2: $filter('translate')('global.enums.publishAuditActions.publish'), language3: 'PUBLISH', types:["DOCUMENT"] },
            { id: 'UNPUBLISH', language1: $filter('translate')('global.enums.publishAuditActions.unpublish'), language2: $filter('translate')('global.enums.publishAuditActions.unpublish'), language3: 'UNPUBLISH', types:["DOCUMENT"] }
        ];
        factory.diligenceAuditActions = [
            { id: 'CREATED', language1: $filter('translate')('global.enums.diligenceAuditActions.created'), language2: $filter('translate')('global.enums.diligenceAuditActions.created'), language3: 'CREATED', types:["DILIGENCE"] },
            { id: 'ACCEPT', language1: $filter('translate')('global.enums.diligenceAuditActions.accepted'), language2: $filter('translate')('global.enums.diligenceAuditActions.accepted'), language3: 'ACCEPT', types:["DILIGENCE"] },
            { id: 'REJECT', language1: $filter('translate')('global.enums.diligenceAuditActions.rejected'), language2: $filter('translate')('global.enums.diligenceAuditActions.rejected'), language3: 'REJECT', types:["DILIGENCE"] },
            { id: 'READ', language1: $filter('translate')('global.enums.diligenceAuditActions.read'), language2: $filter('translate')('global.enums.diligenceAuditActions.read'), language3: 'READ', types:["DILIGENCE"] },
            { id: 'FINISHED', language1: $filter('translate')('global.enums.diligenceAuditActions.finished'), language2: $filter('translate')('global.enums.diligenceAuditActions.finished'), language3: 'FINISHED', types:["DILIGENCE"] },
            { id: 'REMOVED', language1: $filter('translate')('global.enums.diligenceAuditActions.removed'), language2: $filter('translate')('global.enums.diligenceAuditActions.removed'), language3: 'REMOVED', types:["DILIGENCE"] },
            { id: 'SENDTO', language1: $filter('translate')('global.enums.diligenceAuditActions.sentTo'), language2: $filter('translate')('global.enums.diligenceAuditActions.sentTo'), language3: 'SENDTO', types:["DILIGENCE"] },
            { id: 'APPENDEDTO', language1: $filter('translate')('global.enums.diligenceAuditActions.appendedTo'), language2: $filter('translate')('global.enums.diligenceAuditActions.appendedTo'), language3: 'APPENDEDTO', types:["DILIGENCE"] },
            { id: 'RESPONSIBLE', language1: $filter('translate')('global.enums.diligenceAuditActions.responsibleUser'), language2: $filter('translate')('global.enums.diligenceAuditActions.responsibleUser'), language3: 'RESPONSIBLE', types:["DILIGENCE"] },
            { id: 'DUPLICATE_REGISTER', language1: $filter('translate')('global.enums.diligenceAuditActions.duplicateRegister'), language2: $filter('translate')('global.enums.diligenceAuditActions.duplicateRegister'), language3: 'DUPLICATE_REGISTER', types:["DILIGENCE"] },
            { id: 'INIT_DOSSIER', language1: $filter('translate')('global.enums.diligenceAuditActions.initDossier'), language2: $filter('translate')('global.enums.diligenceAuditActions.initDossier'), language3: 'INIT_DOSSIER', types:["DILIGENCE"] }
        ];
        factory.auditTypes = [
            { id: 'DOCUMENT', language1: $filter('translate')('global.enums.auditTypes.document'), language2: $filter('translate')('global.enums.auditTypes.document'), language3: 'DOCUMENT' },
            { id: 'REGISTER_ENTRY', language1: $filter('translate')('global.enums.auditTypes.register_entry'), language2: $filter('translate')('global.enums.auditTypes.register_entry'), language3: 'REGISTER_ENTRY' },
            { id: 'THIRD', language1: $filter('translate')('global.enums.auditTypes.third'), language2: $filter('translate')('global.enums.auditTypes.third'), language3: 'THIRD' },
            { id: 'DOSSIER', language1: $filter('translate')('global.enums.auditTypes.dossier'), language2: $filter('translate')('global.enums.auditTypes.dossier'), language3: 'DOSSIER' },
            { id: 'DOSSIER_TRANSACTION', language1: $filter('translate')('global.enums.auditTypes.dossier_transaction'), language2: $filter('translate')('global.enums.auditTypes.dossier_transaction'), language3: 'DOSSIER_TRANSACTION' },
            { id: 'TASK', language1: $filter('translate')('global.enums.auditTypes.task'), language2: $filter('translate')('global.enums.auditTypes.task'), language3: 'TASK' },
            { id: 'DILIGENCE', language1: $filter('translate')('global.enums.auditTypes.diligence'), language2: $filter('translate')('global.enums.auditTypes.diligence'), language3: 'DILIGENCE' },
			{ id: 'RIGHT_ACCESS', language1: $filter('translate')('global.enums.auditTypes.rightAccess'), language2: $filter('translate')('global.enums.auditTypes.rightAccess'), language3: 'RIGHT_ACCESS' },
			{ id: 'ADDRESS', language1: $filter('translate')('global.enums.auditTypes.address'), language2: $filter('translate')('global.enums.auditTypes.address'), language3: 'ADDRESS' }
        ];
		factory.rightAccessStatuses = [
			{id: 'REQUEST', name: $filter('translate')('global.literals.rightAccessStatus.REQUEST'), class: 'text-warn', icon: 'circle', style: 'text-warn'},
            {id: 'ACCEPTED', name: $filter('translate')('global.literals.rightAccessStatus.ACCEPTED'), class: 'text-success', icon: 'circle', style: 'text-success'},
            {id: 'DENIED', name: $filter('translate')('global.literals.rightAccessStatus.DENIED'), class: 'text-danger', icon: 'circle', style: 'text-danger'},
            {id: 'REVOKED', name: $filter('translate')('global.literals.rightAccessStatus.REVOKED'), class: 'text-grey', icon: 'circle', style: 'text-grey'},
			{id: 'EXPIRED', name: $filter('translate')('global.literals.rightAccessStatus.EXPIRED'), class: 'text-blue', icon: 'circle', style: 'text-blue'}
		];
        factory.notificationTypes = [];
        factory.remittanceTypes = [];
        factory.notificationStatuses = [];
        factory.presetSignCircuit = [];
        factory.pseudoVies = [];
        factory.addressTypes = [];
        factory.entryTypes = [
            { id: 'INPUT', name:'global.literals.INPUT' },
            { id: 'OUTPUT', name: 'global.literals.OUTPUT' }
        ]
        factory.documentRelationTypes = [
            { id: 'DOCUMENT', language1: $filter('translate')('global.enums.documentRelationTypes.document'), language2: $filter('translate')('global.enums.documentRelationTypes.document'), language3: 'DOCUMENT' },
            { id: 'INTERNAL_DOCUMENT', language1: $filter('translate')('global.enums.documentRelationTypes.internal_document'), language2: $filter('translate')('global.enums.documentRelationTypes.internal_document'), language3: 'INTERNAL_DOCUMENT' },
            { id: 'WORK_DOCUMENT', language1: $filter('translate')('global.enums.documentRelationTypes.work_document'), language2: $filter('translate')('global.enums.documentRelationTypes.work_document'), language3: 'WORK_DOCUMENT' },
            { id: 'PAPER_BIN', language1: $filter('translate')('global.enums.documentRelationTypes.paper_bin'), language2: $filter('translate')('global.enums.documentRelationTypes.paper_bin'), language3: 'PAPER_BIN' }
        ]
        factory.operationTypesCode = [
            { id: 'OPERATION', name: 'operation'}//,
            //{ id: 'INVOICE', name: 'invoice'}
        ];
        factory.previousDefinitive = [
            { id: 'PREVIOUS', name: 'previous'},
            { id: 'DEFINITIVE', name: 'definitive'}
        ];
        factory.passwordErrorMessage = '';
        factory.passwordAlertMessage = '';
        
        factory.passwordMessages = function(){
        	var promises = [];
            promises.push($http({ url: './api/users/passwordMessage/true', method: 'GET'}));
            promises.push($http({ url: './api/users/passwordMessage/false', method: 'GET'}));
            return $q.all(promises).then(function(data) {
                factory.passwordErrorMessage = JSOG.decode(data[0].data).message;
                factory.passwordAlertMessage = JSOG.decode(data[1].data).message;
            }).catch(function (error) {
                console.error(error);
                factory.passwordErrorMessage = '';
                factory.passwordAlertMessage = '';
            });
        };
        
        factory.archiveSelectType = [
        	{ id: 'ALL', name: 'global.archive.archive.all'},
        	{ id: 'CAN_NOT_DEFINITIVE', name: 'global.archive.archive.canNotDefinitive'},
        	{ id: 'CAN_DEFINITIVE', name: 'global.archive.archive.canDefinitive'},
            { id: 'DEFINITIVE', name: 'global.archive.archive.definitive'}
        ];
		factory.expiredStates = [
            { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
            { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
        ];

        factory.dossierStatusesTramView = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') },
            { id: 'CLOSED', name: $filter('translate')('CLOSED') } 
        ];

        factory.dossierStatusesTramDossierRightAccess = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') },
            { id: 'CLOSED', name: $filter('translate')('CLOSED') },
            { id: 'TRANSFER', name: $filter('translate')('TRANSFER') },
            { id: 'REJECTED', name: $filter('translate')('REJECTED') },
            { id: 'ARCHIVED', name: $filter('translate')('ARCHIVED') }
        ];

        factory.dossierStatusesTramResponsable = [
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') }
        ];
        
		factory.managedRegOutput = [
            { id: true, name: $filter('translate')('global.reg.literals.managed') },
            { id: false, name: $filter('translate')('global.reg.literals.noManaged') }
        ]

		factory.registerEntryDiligenceStates = [
            { id: 'ACCEPT', name: 'global.reg.diligenceState.ACCEPT' },
            { id: 'REJECT', name: 'global.literals.REJECT' },
            { id: 'READ', name: 'global.reg.diligenceState.READ' },
            { id: 'PENDING_ACCEPT', name: 'global.reg.diligenceState.PENDING_ACCEPT' },
            { id: 'PENDING_RECEPT', name: 'global.reg.diligenceState.PENDING_RECEPT' },
            { id: 'FINISHED', name: 'global.reg.diligenceState.FINISHED' }
        ];
		
		factory.secNotificationTypes = [
            { id: 'DISABLED', description: 'global.sec.literals.secNotificationTypes.DISABLED'},
            { id: 'GLOBAL', description: 'global.sec.literals.secNotificationTypes.GLOBAL'},
            { id: 'PARTIAL', description: 'global.sec.literals.secNotificationTypes.PARTIAL'}
        ];
		
		factory.secNotificationLevels = [
            { id: 'DOCUMENT', description: 'global.sec.literals.secNotificationLevels.DOCUMENT'},
            { id: 'SIGN', description: 'global.sec.literals.secNotificationLevels.SIGN'},
            { id: 'NOTIFICATION', description: 'global.sec.literals.secNotificationLevels.NOTIFICATION'},
            { id: 'CLOSE_TRANSFER', description: 'global.sec.literals.secNotificationLevels.CLOSE_TRANSFER'}
        ];
		
		factory.emgdeLists ={
			entityTypes:[
                { id: "Documento", description: $filter('translate')('global.documents.EMGDE.entityTypes.document')},
                { id: "Agente", description: $filter('translate')('global.documents.EMGDE.entityTypes.agent')},
                { id: "Actividad", description: $filter('translate')('global.documents.EMGDE.entityTypes.activity')},
                { id: "Regulación", description: $filter('translate')('global.documents.EMGDE.entityTypes.regulation')},
                { id: "Relación", description: $filter('translate')('global.documents.EMGDE.entityTypes.relation')}
            ],
			categories:[
                { id: "Grupo de fondos", description: $filter('translate')('global.documents.EMGDE.categories.group')},
                { id: "Fondo", description: $filter('translate')('global.documents.EMGDE.categories.background')},
                { id: "Serie", description: $filter('translate')('global.documents.EMGDE.categories.series')},
                { id: "Agregación", description: $filter('translate')('global.documents.EMGDE.categories.aggregation')},
                { id: "Expediente", description: $filter('translate')('global.documents.EMGDE.categories.dossier')},
                { id: "Documento simple", description: $filter('translate')('global.documents.EMGDE.categories.simpleDocument')}
            ],
			units:[
				{ id: "B", description: 'B - Bytes'},
                { id: "KB", description: 'KB - Kilobytes'},
                { id: "MB", description: 'MB - Megabytes'},
                { id: "GB", description: 'GB - Gigabytes'},
                { id: "TB", description: 'TB - Terabytes'},
                { id: "PB", description: 'PB - Petabytes'},
                { id: "EB", description: 'EB - Exabytes'},
                { id: "ZB", description: 'ZB - Zettabytes'},
                { id: "YB", description: 'YB - Yottabytes'}
			],
			phisicUnits:[
                { id: "MM", description: $filter('translate')('global.documents.EMGDE.phisicUnits.milimeters')},
                { id: "CM", description: $filter('translate')('global.documents.EMGDE.phisicUnits.centimeters')},
                { id: "M", description: $filter('translate')('global.documents.EMGDE.phisicUnits.meters')},
				{ id: "G", description: $filter('translate')('global.documents.EMGDE.weightUnits.gram')},
	            { id: "KG", description: $filter('translate')('global.documents.EMGDE.weightUnits.kilogram')},
 				{ id: "CM3", description: $filter('translate')('global.documents.EMGDE.volumeUnits.cubicMilimeters')},
	            { id: "M3", description: $filter('translate')('global.documents.EMGDE.volumeUnits.cubicMeters')}
			],
			signatureFormats:[
                { id: "TF01", description: $filter('translate')('global.documents.EMGDE.signatureFormats.TF01')},
                { id: "TF02", description: $filter('translate')('global.documents.EMGDE.signatureFormats.TF02')},
                { id: "TF03", description: $filter('translate')('global.documents.EMGDE.signatureFormats.TF03')},
				{ id: "TF04", description: $filter('translate')('global.documents.EMGDE.signatureFormats.TF04')},
                { id: "TF05", description: $filter('translate')('global.documents.EMGDE.signatureFormats.TF05')},
                { id: "TF06", description: $filter('translate')('global.documents.EMGDE.signatureFormats.TF06')}
			],
			signatureProfiles:[
                { id: "EPES", description: 'EPES'},
                { id: "T", description: 'T'},
                { id: "C", description: 'C'},
				{ id: "X", description: 'X'},
                { id: "XL", description: 'XL'},
                { id: "A", description: 'A'},
				{ id: "BASELINE B-Level", description: 'BASELINE B-Level'},
                { id: "BASELINE T-Level", description: 'BASELINE T-Level'},
                { id: "BASELINE LT-Level", description: 'BASELINE LT-Level'},
				{ id: "BASELINE LTS-Level", description: 'BASELINE LTS-Level'},
                { id: "LT", description: 'LT'}
			],
			documentaryTypes:[
                { id: "TD01", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD01')},
                { id: "TD02", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD02')},
                { id: "TD03", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD03')},
				{ id: "TD04", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD04')},
                { id: "TD05", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD05')},
				{ id: "TD06", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD06')},
                { id: "TD07", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD07')},
                { id: "TD08", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD08')},
				{ id: "TD09", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD09')},
                { id: "TD10", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD10')},
                { id: "TD11", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD11')},
                { id: "TD12", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD12')},
                { id: "TD13", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD13')},
				{ id: "TD14", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD14')},
                { id: "TD15", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD15')},
                { id: "TD16", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD16')},
                { id: "TD17", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD17')},
                { id: "TD18", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD18')},
				{ id: "TD19", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD19')},
                { id: "TD20", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD20')},
                { id: "TD51", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD51')},
                { id: "TD52", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD52')},
                { id: "TD53", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD53')},
				{ id: "TD54", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD54')},
                { id: "TD55", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD55')},
                { id: "TD56", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD56')},
                { id: "TD57", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD57')},
                { id: "TD58", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD58')},
				{ id: "TD59", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD59')},
                { id: "TD60", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD60')},
                { id: "TD61", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD61')},
                { id: "TD62", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD62')},
                { id: "TD63", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD63')},
				{ id: "TD64", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD64')},
                { id: "TD65", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD65')},
                { id: "TD66", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD66')},
                { id: "TD67", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD67')},
                { id: "TD68", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD68')},
				{ id: "TD69", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD69')},
                { id: "TD99", description: $filter('translate')('global.documents.EMGDE.documentalTypes.TD99')}
			],
			elaborationStatuses:[
                { id: "EE01", description: $filter('translate')('global.documents.EMGDE.elaborationStatuses.EE01') },
                { id: "EE02", description: $filter('translate')('global.documents.EMGDE.elaborationStatuses.EE02') },
                { id: "EE03", description: $filter('translate')('global.documents.EMGDE.elaborationStatuses.EE03') },
                { id: "EE04", description: $filter('translate')('global.documents.EMGDE.elaborationStatuses.EE04') },
                { id: "EE99", description: $filter('translate')('global.documents.EMGDE.elaborationStatuses.EE99') }
            ],
			classificationTypes:[
                { id: "SIA", description: $filter('translate')('global.documents.EMGDE.classificationTypes.SIA') },
                { id: "Funcional", description: $filter('translate')('global.documents.EMGDE.classificationTypes.Funcional') }
            ],
			documentOrigins:[
                { id: "0", description: $filter('translate')('global.documents.EMGDE.documentOrigins.0') },
                { id: "1", description: $filter('translate')('global.documents.EMGDE.documentOrigins.1') }
            ],
			transactionTypes:[
                { id: "0", description: $filter('translate')('global.documents.EMGDE.transactionTypes.0') },
                { id: "1", description: $filter('translate')('global.documents.EMGDE.transactionTypes.1') }
            ],
			securityLevels:[
                { id: "Bajo", description: $filter('translate')('global.documents.EMGDE.securityLevels.lower') },
				{ id: "Medio", description: $filter('translate')('global.documents.EMGDE.securityLevels.middle') },
                { id: "Alto", description: $filter('translate')('global.documents.EMGDE.securityLevels.hight') }
            ],
			accesTypes:[
                { id: "Libre", description: $filter('translate')('global.documents.EMGDE.accesTypes.free') },
				{ id: "Parcialmente restringido", description: $filter('translate')('global.documents.EMGDE.accesTypes.partiallyRestricted') },
                { id: "Restringido", description: $filter('translate')('global.documents.EMGDE.accesTypes.restricted') }
            ],
			limitCauses:[
				{id: "A", description: $filter('translate')('global.documents.EMGDE.limitCauses.A')},
               	{id: "B", description: $filter('translate')('global.documents.EMGDE.limitCauses.B')},
               	{id: "C", description: $filter('translate')('global.documents.EMGDE.limitCauses.C')},
               	{id: "D", description: $filter('translate')('global.documents.EMGDE.limitCauses.D')},
               	{id: "E", description: $filter('translate')('global.documents.EMGDE.limitCauses.E')},
               	{id: "F", description: $filter('translate')('global.documents.EMGDE.limitCauses.F')},
               	{id: "G", description: $filter('translate')('global.documents.EMGDE.limitCauses.G')},
               	{id: "H", description: $filter('translate')('global.documents.EMGDE.limitCauses.H')},
               	{id: "I", description: $filter('translate')('global.documents.EMGDE.limitCauses.I')},
               	{id: "J", description: $filter('translate')('global.documents.EMGDE.limitCauses.J')},
               	{id: "K", description: $filter('translate')('global.documents.EMGDE.limitCauses.K')},
               	{id: "L", description: $filter('translate')('global.documents.EMGDE.limitCauses.L')},
	            {id: "M", description: $filter('translate')('global.documents.EMGDE.limitCauses.M')}
            ],
			valueTypes:[
				{id: "Administrativo", description: $filter('translate')('global.documents.EMGDE.valueTypes.administrative')},
               	{id: "Fiscal", description: $filter('translate')('global.documents.EMGDE.valueTypes.fiscal')},
               	{id: "Jurídico", description: $filter('translate')('global.documents.EMGDE.valueTypes.legal')},
               	{id: "Otros", description: $filter('translate')('global.documents.EMGDE.valueTypes.others')}
            ],
			secundaryValues:[
				{id: "Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.yes')},
               	{id: "Valor testimonial: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes1'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes1')},
               	{id: "Valor informativo: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes2'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes2')},
               	{id: "Valor histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes3'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes3')},
               	{id: "Valor testimonial o informativo: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes4'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes4')},
               	{id: "Valor testimonial o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes5'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes5')},
               	{id: "Valor informativo o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes6'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes6')},
               	{id: "Valor testimonial, informativo o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes7'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes7')},
               	{id: "No", description: $filter('translate')('global.documents.EMGDE.secundaryValues.no'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.no')},
               	{id: "Sin cobertura de calificación", description: $filter('translate')('global.documents.EMGDE.secundaryValues.without'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.without')}
            ],
			essentialDocuments:[
				{id: true, description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes')},
               	{id: false, description: $filter('translate')('global.documents.EMGDE.secundaryValues.no')}
            ],
			archiveFases:[
				{id: "Archivo Central", description: $filter('translate')('global.documents.EMGDE.archiveFases.central')},
               	{id: "Archivo Intermedio", description: $filter('translate')('global.documents.EMGDE.archiveFases.intermediate')},
               	{id: "Archivo Histórico", description: $filter('translate')('global.documents.EMGDE.archiveFases.historical')}
            ],
			opinionTypes:[
				{id: "CP", description: $filter('translate')('global.documents.EMGDE.opinionTypes.CP')},
               	{id: "EP", description: $filter('translate')('global.documents.EMGDE.opinionTypes.EP')},
               	{id: "ET", description: $filter('translate')('global.documents.EMGDE.opinionTypes.ET')},
               	{id: "PD", description: $filter('translate')('global.documents.EMGDE.opinionTypes.PD')}
            ],
			dossierStatuses:[
				{id:"E01", description:$filter('translate')('global.documents.EMGDE.dossierStatuses.E01')},
				{id:"E02", description:$filter('translate')('global.documents.EMGDE.dossierStatuses.E02')},
				{id:"E03", description:$filter('translate')('global.documents.EMGDE.dossierStatuses.E03')}
			]
		};
		
		factory.applicableTo = [
			{ id: 'TOWNS', language1: $filter('translate')('global.enums.territorialGroupType.towns'), language2: $filter('translate')('global.enums.territorialGroupType.towns'), language3: 'TOWNS' },
			{ id: 'STREET_SECTIONS', language1: $filter('translate')('global.enums.territorialGroupType.streetSections'), language2: $filter('translate')('global.enums.territorialGroupType.streetSections'), language3: 'STREET_SECTIONS' },
		];
		
		factory.automatismInitDossierTypes = [
			{ id: undefined, name:'global.literals.empty' },
            { id: 'REGISTER', name:'global.literals.REGISTER' },
            { id: 'DOSSIER', name: 'global.literals.dossier' },
            { id: 'TASK', name: 'global.literals.TASK' }
        ];
		factory.tramClassifications = [];
		factory.procedureClassifications = [];
		factory.backendOptions = [
    		{id:'STRING', description: $filter("translate")('global.customFields.STRING')},
    		{id:'INTEGER', description: $filter("translate")('global.customFields.INTEGER')},
    		{id:'DECIMAL', description: $filter("translate")('global.customFields.DECIMAL')},
    		{id:'DATETIME', description: $filter("translate")('global.customFields.DATETIME')},
    		{id:'ADDRESS', description: $filter("translate")('global.customFields.ADDRESS')}
    	];
		factory.frontendOptions = [
    		{id:'INPUT', description: $filter("translate")('global.customFields.INPUT')},
            {id:'TEXTAREA', description: $filter("translate")('global.customFields.TEXTAREA')},
            {id:'SELECT', description: $filter("translate")('global.customFields.SELECT')},
            {id:'MULTIPLESELECT', description: $filter("translate")('global.customFields.MULTIPLESELECT')},
            {id:'CHECKBOX', description: $filter("translate")('global.customFields.CHECKBOX')},
            {id:'RADIO', description: $filter("translate")('global.customFields.RADIO')},
            {id:'JSON', description: $filter("translate")('global.customFields.JSON')},
            {id:'SELECT_LINKED', description: $filter("translate")('global.customFields.SELECT_LINKED')},
            {id:'STRUCTURAL_SELECT', description: $filter("translate")('global.customFields.STRUCTURAL_SELECT')},
            {id:'CF_GROUP', description: $filter("translate")('global.customFields.CF_GROUP')}
    	];
        factory.publishSite = [];

        factory.resetGlobalData = function() {
        	factory.documentTypes = [];
            factory.templateTypes = [];
            factory.templates = [];
            factory.archiveClassifications = [];
            factory.archiveClassificationsTree = [];
            factory.documentViewModeTypes = [];
            factory.sections = [];
            factory.modules = [];
            factory.signActionTypes = [];
            factory.registerDiligenceTypes = [];
            factory.publishPlugins = [];
            factory.publishList = [];
        	factory.allProfiles = [];
        	factory.registerEntryOffices = [];
        	factory.defaultRegisterEntryOffice = undefined;
            factory.registerEntryChannelsInput = [];
            factory.allRegisterEntryChannelsInput = [];
            factory.registerEntryChannelsOutput = [];
            factory.allRegisterEntryChannelsOutput = [];
            factory.registerEntryDocumentTypesInput = [];
            factory.registerEntryDocumentTypesOutput = [];
            factory.registerEntryClassificationsInput = [];
            factory.registerEntryClassificationsOutput = [];
            factory.registerEntryDocumentTypesInput = [];
            factory.registerEntryDocumentTypesOutput = [];
            factory.registerEntryClassificationsInput = [];
            factory.registerEntryClassificationsOutput = [];
            factory.familyProcedures = [];
            factory.familyProceduresTree = [];
            factory.procedures = [];
            factory.notificationTypes = [];
            factory.remittanceTypes = [];
            factory.notificationStatuses = [];
            factory.presetSignCircuit = [];
            factory.proposals = [];
            factory.languages = [];
            factory.configuration = [];
            factory.pluginUibutton = [];
            factory.menuWithPermissions = [];
            factory.footerClaims = [];
            factory.roleInteresteds = [];
            $rootScope.existsOptionsRoleInterested = false;
            factory.proposalTypes = [];
            factory.signstatestypes = [];
            factory.transactiontypes = [];
            factory.dossierTramitationTypes = [];
            factory.taskTemplates = [];
            factory.organs = [];
            factory.sessionTypes = [];
            factory.secTemplates = [];
            factory.organMemberRols = [];
            factory.meetingLocations = [];
            factory.bookTypes = [];
            factory.customFields = [];
            factory.secretaryProfile = undefined;
            factory.interventionProfile = undefined;
            factory.publictramtemplates = [];
            factory.operationTypes = [];
            factory.diligenceStampTypes = [];
            factory.pseudoVies = [];
            factory.addressTypes = [];
            factory.relatedDossierRelations = [];
            factory.decreeDelegatedTypes = [];
            factory.tramitationPhase = [];
            factory.taskTypes = [];
            factory.taskTypeStatuses = [];
            factory.taskOrigins = [];
			factory.userFilters = [];
			factory.userCountersConfiguration = [];
			factory.tramClassifications = [];
			factory.procedureClassifications = [];
			factory.spelFunctions = [];
			factory.identificationReferenceTypes = [];
			factory.genders = [];
			factory.treatments = [];
			factory.relationshipLanguages = [];
			factory.councillors = [];
			factory.councillorsTree = [];
			factory.newDocumentsCFConfiguration = {};
			if(CacheFactory.get("globalDataCache")){
            	CacheFactory.get("globalDataCache").removeAll();
            }
            factory.procedureProposalType =[];
            factory.publishSite = [];
        }
        
        factory.taskTypes = [];
        factory.taskTypeStatuses = [];
        factory.taskOrigins = [];
        
        factory.initializeTaskLists = function(){
        	var promises = [];
        	
        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('TaskType')){
        		var cache = CacheFactory.get('globalDataCache').get('TaskType');
        		if(cache && cache.length > 0){
        			var haveProfile = function (profiles) {
        				var userProfiles = $linq($rootScope.LoggedUser.userProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
                        userProfiles = $linq(userProfiles).distinctBy("x => x.id").toArray();
                        profiles = Array.isArray(profiles) ? profiles : [profiles];

                        var intersectedProfiles = $linq(userProfiles).intersect(profiles, "(x,y) => x.id == y.id").toArray();

                        return intersectedProfiles.length != 0;
                    };

                    factory.taskTypes = [];

                    var tTypes = JSOG.decode(angular.fromJson(cache));

                    _.forEach(tTypes, function (item) {
                        var taskTypeProfiles = [];

                        _.forEach(item.profiles, function (value) {
                        	taskTypeProfiles.push(value.profile);
                        });

                        if (taskTypeProfiles.length > 0) {
                            if(haveProfile(taskTypeProfiles)) {
                                factory.taskTypes.push(item);
                            }                        	
                        } else {
                        	factory.taskTypes.push(item);
                        }
                    });
                    
        			factory.taskTypes = $linq(factory.taskTypes).where("x => !x.abstractTaskType").toArray();
        		}
        	}
        	if(!factory.taskTypes || factory.taskTypes.length == 0){
        		$http({url: './api/task_type/calculateCustomFields/-1/true/-1', method: 'GET'}).then(function(data) {
    				factory.newTasksCFConfiguration = ((data && data.data)?JSOG.decode(data.data):{});
    			}).catch(function (error) {
    				factory.newTasksCFConfiguration = {};
    			});
        		
        		promises.push(RestService.findAll('TaskType'));
        	}else if(!factory.newTasksCFConfiguration || Object.keys(factory.newTasksCFConfiguration).length == 0){
        		$http({url: './api/task_type/calculateCustomFields/-1/true/-1', method: 'GET'}).then(function(data) {
    				factory.newTasksCFConfiguration = ((data && data.data)?JSOG.decode(data.data):{});
    			}).catch(function (error) {
    				factory.newTasksCFConfiguration = {};
    			});
        	}
        	
        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('TaskTypeStatus')){
        		var cache = CacheFactory.get('globalDataCache').get('TaskTypeStatus');
        		if(cache && cache.length > 0){
        			factory.taskTypeStatuses = JSOG.decode(angular.fromJson(cache));
        		}
        	}
        	if(!factory.taskTypeStatuses || factory.taskTypeStatuses.length == 0){
        		promises.push(RestService.findAll('TaskTypeStatus'));
        	}
        	
        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('TaskOrigin')){
        		var cache = CacheFactory.get('globalDataCache').get('TaskOrigin');
        		if(cache && cache.length > 0){
        			factory.taskOrigins = JSOG.decode(angular.fromJson(cache));
        		}
        	}
        	if(!factory.taskOrigins || factory.taskOrigins.length == 0){
        		promises.push(RestService.findAll('TaskOrigin'));
        	}
        	
            return $q.all(promises)
            .then(function(data) {
            	var dataPos = 0;
            	var altPos = 0;
            	if(data && data.length > 0){
                	if(!factory.taskTypes || factory.taskTypes.length == 0) {
            			var haveProfile = function (profiles) {
            				var userProfiles = $linq($rootScope.LoggedUser.userProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
                            userProfiles = $linq(userProfiles).distinctBy("x => x.id").toArray();
                            profiles = Array.isArray(profiles) ? profiles : [profiles];

                            var intersectedProfiles = $linq(userProfiles).intersect(profiles, "(x,y) => x.id == y.id").toArray();

                            return intersectedProfiles.length != 0;
                        };

                        factory.taskTypes = [];

                        if(data[dataPos].config && data[dataPos].config.params && 
                				data[dataPos].config.params.object_type && data[dataPos].config.params.object_type == 'TaskType') {
                			altPos = dataPos;
                		} else {
                			var pos = 0;
                			_.forEach(data, function (item) {
                                if(item.config && item.config.params && 
                                		item.config.params.object_type && item.config.params.object_type == 'TaskType') {
                                	altPos = pos;
                                }
                                pos++;
                            });
                		}
                        
                        var tTypes = JSOG.decode(data[altPos].data);

                        _.forEach(tTypes, function (item) {
                            var taskTypeProfiles = [];

                            _.forEach(item.profiles, function (value) {
                            	taskTypeProfiles.push(value.profile);
                            });

                            if (taskTypeProfiles.length > 0) {
                                if(haveProfile(taskTypeProfiles)) {
                                    factory.taskTypes.push(item);
                                }                        	
                            } else {
                            	factory.taskTypes.push(item);
                            }
                        });
                        
            			factory.taskTypes = $linq(factory.taskTypes).where("x => !x.abstractTaskType").toArray();
                        try {
                        	CacheFactory.get("globalDataCache").put('TaskType', data[altPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }

                	if(!factory.taskTypeStatuses || factory.taskTypeStatuses.length == 0) {
                		if(data[dataPos].config && data[dataPos].config.params && 
                				data[dataPos].config.params.object_type && data[dataPos].config.params.object_type == 'TaskTypeStatus') {
                			altPos = dataPos;
                		} else {
                			var pos = 0;
                			_.forEach(data, function (item) {
                                if(item.config && item.config.params && 
                                		item.config.params.object_type && item.config.params.object_type == 'TaskTypeStatus') {
                                	altPos = pos;
                                }
                                pos++;
                            });
                		}
                        factory.taskTypeStatuses = JSOG.decode(data[altPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('TaskTypeStatus', data[altPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }

                	if(!factory.taskOrigins || factory.taskOrigins.length == 0) { //data[dataPos].config.params.object_type
                		if(data[dataPos].config && data[dataPos].config.params && 
                				data[dataPos].config.params.object_type && data[dataPos].config.params.object_type == 'TaskOrigin') {
                			altPos = dataPos;
                		} else {
                			var pos = 0;
                			_.forEach(data, function (item) {
                                if(item.config && item.config.params && 
                                		item.config.params.object_type && item.config.params.object_type == 'TaskOrigin') {
                                	altPos = pos;
                                }
                                pos++;
                            });
                		}
                        factory.taskOrigins = JSOG.decode(data[altPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('TaskOrigin', data[altPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
            	}
            }).catch(function(error) {
                factory.taskTypes = [];
                factory.taskTypeStatuses = [];
                factory.taskOrigins = [];
            });
        }

        factory.loadGlobalData = function() {
        	var deferrend = $q.defer();
        	var docTypesChanged = false;
            var configChanged = false;
            var callTranslate = false;
            
            var fixDocumentTypes = function(documentTypes) {
                var fixedDocumentTypes = [];

                _.forEach(documentTypes, function (item) {
                    _.forEach(item.customFields, function(cf, index) {
                        item.customFields[index].documentType = undefined;
                    });

                    fixedDocumentTypes.push({
                        id: item.id,
                        isPublic: item.isPublic,
                        documentFileAttachment: item.documentFileAttachment,
                        documentTemplateSelection: item.documentTemplateSelection,
						documentPhysicalSelection: item.documentPhysicalSelection,
                        archiveClassification: item.archiveClassification,
                        language1: item.language1,
                        language2: item.language2,
                        language3: item.language3,
                        customFields: item.customFields,
                        acronym: item.acronym,
                        profiles: item.profiles,
                        eniOrigenCiudadanoAdministracion: item.eniOrigenCiudadanoAdministracion,
                        tipoDocumental: item.tipoDocumental,
                        allowGenerateFromContentDocumentTypes: item.allowGenerateFromContentDocumentTypes,
                        includeRegisterEntry: item.includeRegisterEntry,
                        includeNotification: item.includeNotification,
                        profilesTotalAcces: item.profilesTotalAcces,
						canChangeType: item.canChangeType,
						canChangeToThisType: item.canChangeToThisType
                    });
                });

                return fixedDocumentTypes;
            };
            
            var promises = [];
            if(!factory.documentTypes || factory.documentTypes.length == 0) {
            	
            	$http({url: './api/doc/document_type/calculateCustomFields/-1/true/-1', method: 'GET'}).then(function(data) {
    				factory.newDocumentsCFConfiguration = ((data && data.data)?JSOG.decode(data.data):{});
    			}).catch(function (error) {
    				factory.newDocumentsCFConfiguration = {};
    			});
            	
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=DocumentType')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=DocumentType');
            		if(cache && cache.length > 0){
            			var dtypes = fixDocumentTypes(JSOG.decode(angular.fromJson(cache)));
            			factory.documentTypes = new SortedArray($linq(dtypes).where("x => x.isPublic").toArray(), Language.getActiveColumn()).sort();
                        factory.allDocumentTypes = new SortedArray(dtypes, Language.getActiveColumn()).sort();
                        docTypesChanged = true;
            		}
            	}
            	if(!factory.documentTypes || factory.documentTypes.length == 0){
            		promises.push(RestService.findAll('DocumentType', undefined));
            	}
            }else if(!factory.newDocumentsCFConfiguration || Object.keys(factory.newDocumentsCFConfiguration).length == 0){
            	$http({url: './api/doc/document_type/calculateCustomFields/-1/true/-1', method: 'GET'}).then(function(data) {
    				factory.newDocumentsCFConfiguration = ((data && data.data)?JSOG.decode(data.data):{});
    			}).catch(function (error) {
    				factory.newDocumentsCFConfiguration = {};
    			});
            }

            if(!factory.templateTypes || factory.templateTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=TemplateType')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=TemplateType');
            		if(cache && cache.length > 0){
            			factory.templateTypes = JSOG.decode(angular.fromJson(cache));
                        factory.templateTree = factory.templateTypes;
            		}
            	}
            	if(!factory.templateTypes || factory.templateTypes.length == 0) {
                    promises.push(RestService.findAll('TemplateType', undefined));
            	}
            }

            if(!factory.templates || factory.templates.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Template')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Template');
            		if(cache && cache.length > 0){
            			factory.templates = JSOG.decode(angular.fromJson(cache));
            			var templatesAux = angular.copy(factory.templates);
                        _.forEach(templatesAux, function(value) {
                            value.parent = value.templateType;
                            value.idOriginal = value.id;
                            value.id = '-'+value.id;
                            value[Language.getActiveColumn()] = value.name;
                            factory.templateTree.push(value);
                        });

            		}
            	}
            	if(!factory.templates || factory.templates.length == 0) {
            		promises.push(RestService.findAll('Template', undefined));
            	}
            }

            if(!factory.archiveClassifications || factory.archiveClassifications.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=ArchiveClassification')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=ArchiveClassification');
            		if(cache && cache.length > 0){
            			factory.archiveClassifications = JSOG.decode(angular.fromJson(cache));
            			if(factory.archiveClassifications && factory.archiveClassifications.length > 0){
            				factory.archiveClassificationsTree = CommonService.getTreeData(factory.archiveClassifications, Language.getActiveColumn());
            			}
            		}
            	}
            	if(!factory.archiveClassifications || factory.archiveClassifications.length == 0) {
            		promises.push(RestService.findAll('ArchiveClassification', undefined));
            	}
            }

            if(!factory.documentViewModeTypes || factory.documentViewModeTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=documentViewModeType')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=documentViewModeType');
            		if(cache){
            			factory.documentViewModeTypes =JSOG.decode(angular.fromJson(cache));
                        if(factory.documentViewModeTypes.length == 0) {
                            factory.documentViewModeTypes.push('empty');
                        }
            		}
            	}
            	if(!factory.documentViewModeTypes || factory.documentViewModeTypes.length == 0) {
                    promises.push(RestService.findAll('documentViewModeType', undefined));
            	}
            }

            if(!factory.sections || factory.sections.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Section')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Section');
            		if(cache && cache.length > 0){
            			factory.sections = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.sections || factory.sections.length == 0) {
                    promises.push(RestService.findAll('Section', undefined));
            	}
            }

            if(!factory.modules || factory.modules.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Module')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Module');
            		if(cache && cache.length > 0){
            			factory.modules = JSOG.decode(angular.fromJson(cache));
                        var AnnexaPendingCounterFactory = angular.element(document.body).injector().get('AnnexaPendingCounterFactory');
                        AnnexaPendingCounterFactory.updateModuleCounters();
                        AnnexaPendingCounterFactory.runCounters();
            		}
            	}
            	if(!factory.modules || factory.modules.length == 0) {
            		promises.push(RestService.findAll('Module', undefined));
            	}
            }

            if(!factory.signActionTypes || factory.signActionTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=SignActionType')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=SignActionType');
            		if(cache && cache.length > 0){
            			factory.signActionTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.signActionTypes || factory.signActionTypes.length == 0) {
                    promises.push(RestService.findAll('SignActionType', undefined));
            	}
            }

            if(!factory.registerDiligenceTypes || factory.registerDiligenceTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RegisterDiligenceType')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RegisterDiligenceType');
            		if(cache && cache.length > 0){
            			factory.registerDiligenceTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.registerDiligenceTypes || factory.registerDiligenceTypes.length == 0) {
                    promises.push(RestService.findAll('RegisterDiligenceType', undefined));
            	}
            }

            if(!factory.publishPlugins || factory.publishPlugins.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/plugin/publish/list')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/plugin/publish/list');
            		if(cache && cache.length > 0){
            			factory.publishPlugins = JSOG.decode(angular.fromJson(cache));
            			factory.publishList = $linq(factory.publishPlugins).select(function (x) { return { id: x.target, name: x.name}; }).toArray();
            		}
            	}
            	if(!factory.publishPlugins || factory.publishPlugins.length == 0) {
                    promises.push($http({ method: 'GET', url: './api/plugin/publish/list', headers: {'Content-Type': 'application/json'}}));
            	}
            }

            if(!factory.allProfiles || factory.allProfiles.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Profile&origin=allProfiles')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Profile&origin=allProfiles');
            		if(cache && cache.length > 0){
            			factory.allProfiles = JSOG.decode(angular.fromJson(cache));
            			if(factory.allProfiles && factory.allProfiles.length > 0){
                        	factory.allProfiles = new SortedArray(factory.allProfiles, Language.getActiveColumn()).sort();
                        }
            		}
            	}
            	if(!factory.allProfiles || factory.allProfiles.length == 0) {
                    promises.push(RestService.findAll('Profile', 'allProfiles'));
            	}
            }

            if(!factory.registerEntryOffices || factory.registerEntryOffices.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RegisterEntryOffice&origin=registerEntryOffices')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RegisterEntryOffice&origin=registerEntryOffices');
            		if(cache && cache.length > 0){
            			var haveProfile = function (profiles) {
                            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
                            userProfiles = $linq(userProfiles).distinctBy("x => x.id").toArray();

                            profiles = Array.isArray(profiles) ? profiles : [profiles];

                            var intersectedProfiles = $linq(userProfiles).intersect(profiles, "(x,y) => x.id == y.id").toArray();

                            return intersectedProfiles.length != 0;
                        };

                        factory.registerEntryOffices = [];

                        var regEntryOffices = JSOG.decode(angular.fromJson(cache));

                        _.forEach(regEntryOffices, function (item) {
                            var registerEntryOfficeProfiles = [];

                            _.forEach(item.profiles, function (value) {
                                registerEntryOfficeProfiles.push(value.profile);
                            });

                            if(registerEntryOfficeProfiles.length == 0 || haveProfile(registerEntryOfficeProfiles)) {
                                factory.registerEntryOffices.push(item);
                            }
                        });
                        
                        factory.registerEntryOffices = $linq(factory.registerEntryOffices).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            		}
            	}
                if(!factory.registerEntryOffices || factory.registerEntryOffices.length == 0) {
                	promises.push(RestService.findAll('RegisterEntryOffice', 'registerEntryOffices'));
                }
            }

            if(!factory.registerEntryChannelsInput || factory.registerEntryChannelsInput.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_channel/entry_type/INPUT')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/reg/register_entry_channel/entry_type/INPUT');
            		if(cache && cache.length > 0){
            			factory.registerEntryChannelsInput = JSOG.decode(angular.fromJson(cache));
            			factory.registerEntryChannelsInput = $linq(factory.registerEntryChannelsInput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            		}
            	}
            	if(!factory.registerEntryChannelsInput || factory.registerEntryChannelsInput.length == 0) {
            		promises.push($http({ url: './api/reg/register_entry_channel/entry_type/INPUT', method: 'GET', headers: {'Content-Type': 'application/json'}}));
            	}
            }
            
            if(!factory.allRegisterEntryChannelsInput || factory.allRegisterEntryChannelsInput.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_channel/entry_type/all/INPUT')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/reg/register_entry_channel/entry_type/all/INPUT');
            		if(cache && cache.length > 0){
            			factory.allRegisterEntryChannelsInput = JSOG.decode(angular.fromJson(cache));
            			factory.allRegisterEntryChannelsInput = $linq(factory.allRegisterEntryChannelsInput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            		}
            	}
            	if(!factory.allRegisterEntryChannelsInput || factory.allRegisterEntryChannelsInput.length == 0) {
            		promises.push($http({ url: './api/reg/register_entry_channel/entry_type/all/INPUT', method: 'GET', headers: {'Content-Type': 'application/json'}}));
            	}
           }

            if(!factory.registerEntryChannelsOutput || factory.registerEntryChannelsOutput.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_channel/entry_type/OUTPUT')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/reg/register_entry_channel/entry_type/OUTPUT');
            		if(cache && cache.length > 0){
            			factory.registerEntryChannelsOutput = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.registerEntryChannelsOutput || factory.registerEntryChannelsOutput.length == 0) {
                    promises.push($http({ url: './api/reg/register_entry_channel/entry_type/OUTPUT', method: 'GET', headers: {'Content-Type': 'application/json'}}));
            	}
            }
            
            if(!factory.allRegisterEntryChannelsOutput || factory.allRegisterEntryChannelsOutput.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_channel/entry_type/all/OUTPUT')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/reg/register_entry_channel/entry_type/all/OUTPUT');
            		if(cache && cache.length > 0){
            			factory.allRegisterEntryChannelsOutput = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.allRegisterEntryChannelsOutput || factory.allRegisterEntryChannelsOutput.length == 0) {
                    promises.push($http({ url: './api/reg/register_entry_channel/entry_type/all/OUTPUT', method: 'GET', headers: {'Content-Type': 'application/json'}}));
            	}
           }

            if(!factory.registerEntryDocumentTypesInput || factory.registerEntryDocumentTypesInput.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_document_type/entry_type/INPUT')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/reg/register_entry_document_type/entry_type/INPUT');
            		if(cache && cache.length > 0){
            			factory.registerEntryDocumentTypesInput = JSOG.decode(angular.fromJson(cache));
            			factory.registerEntryDocumentTypesInput = $linq(factory.registerEntryDocumentTypesInput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            		}
            	}
            	if(!factory.registerEntryDocumentTypesInput || factory.registerEntryDocumentTypesInput.length == 0) {
            		promises.push($http({ url: './api/reg/register_entry_document_type/entry_type/INPUT', method: 'GET', headers: {'Content-Type': 'application/json'}}));
            	}
            }

            if(!factory.registerEntryDocumentTypesOutput || factory.registerEntryDocumentTypesOutput.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_document_type/entry_type/OUTPUT')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/reg/register_entry_document_type/entry_type/OUTPUT');
            		if(cache && cache.length > 0){
            			factory.registerEntryDocumentTypesOutput = JSOG.decode(angular.fromJson(cache));
            			factory.registerEntryDocumentTypesOutput = $linq(factory.registerEntryDocumentTypesOutput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            		}
            	}
            	if(!factory.registerEntryDocumentTypesOutput || factory.registerEntryDocumentTypesOutput.length == 0) {
            		promises.push($http({ url: './api/reg/register_entry_document_type/entry_type/OUTPUT', method: 'GET', headers: {'Content-Type': 'application/json'}}));
            	}
            }

            if(!factory.registerEntryClassificationsInput || factory.registerEntryClassificationsInput.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_classification/entry_type/INPUT')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/reg/register_entry_classification/entry_type/INPUT');
            		if(cache && cache.length > 0){
            			factory.registerEntryClassificationsInput = JSOG.decode(angular.fromJson(cache));
            			factory.registerEntryClassificationsInput = $linq(factory.registerEntryClassificationsInput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            		}
            	}
            	if(!factory.registerEntryClassificationsInput || factory.registerEntryClassificationsInput.length == 0) {
            		promises.push($http({ url: './api/reg/register_entry_classification/entry_type/INPUT', method: 'GET', headers: {'Content-Type': 'application/json'}}));
            	}
            }

            if(!factory.registerEntryClassificationsOutput || factory.registerEntryClassificationsOutput.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/reg/register_entry_classification/entry_type/OUTPUT')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/reg/register_entry_classification/entry_type/OUTPUT');
            		if(cache && cache.length > 0){
            			factory.registerEntryClassificationsOutput = JSOG.decode(angular.fromJson(cache));
            			factory.registerEntryClassificationsOutput = $linq(factory.registerEntryClassificationsOutput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            		}
            	}
            	if(!factory.registerEntryClassificationsOutput || factory.registerEntryClassificationsOutput.length == 0) {
            		promises.push($http({ url: './api/reg/register_entry_classification/entry_type/OUTPUT', method: 'GET', headers: {'Content-Type': 'application/json'}}));
            	}
            }

            if(!factory.familyProcedures || factory.familyProcedures.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=FamilyProcedure')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=FamilyProcedure');
            		if(cache && cache.length > 0){
            			factory.familyProcedures = JSOG.decode(angular.fromJson(cache));
            			factory.familyProcedures = $linq(factory.familyProcedures).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
           	    		factory.familyProceduresTree = CommonService.getTreeData(angular.copy(factory.familyProcedures), Language.getActiveColumn());
            		}
            	}
            	if(!factory.familyProcedures || factory.familyProcedures.length == 0) {
                    promises.push(RestService.findAll('FamilyProcedure', undefined));
            	}
            }

            if(!factory.procedures || factory.procedures.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData');
            		if(cache && cache.length > 0){
            			factory.procedures = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.procedures || factory.procedures.length == 0) {
            		promises.push(RestService.findAll('Procedure', 'globalData'));
            		
            	}
            }

            if(!factory.presetSignCircuit || factory.presetSignCircuit.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit');
            		if(cache && cache.length > 0){
            			factory.presetSignCircuit = JSOG.decode(angular.fromJson(cache));
            			if(factory.presetSignCircuit && factory.presetSignCircuit.length > 0){
	            			factory.presetSignCircuit = $linq(factory.presetSignCircuit).where("x => !x.user || !x.user.id").orderBy(function(x) {
	                            if(x[Language.getActiveColumn()]) {
	                                return x[Language.getActiveColumn()].toUpperCase();
	                            }
	
	                            return '';
	                        }).toArray();
            			}
            		}
            	}
            	if(!factory.presetSignCircuit || factory.presetSignCircuit.length == 0) {
                    promises.push(RestService.findAll('PresetSignCircuit', undefined));
            	}
            }

            var isThirdIdentificationDocumentTypesTranslated = $linq(factory.thirdIdentificationDocumentTypes).count("x => x.id == 'OTHER' && x."+Language.getActiveColumn()+" == 'OTHER'") == 0;

            var translations = [];
            
            if(!isThirdIdentificationDocumentTypesTranslated) {
            	translations.push('global.thirds.literals.passport');
            	translations.push('global.literals.other');
            }

            var isThirdTypesTranslated = $linq(factory.thirdTypes).count("x => x.id == 'PHISICAL' && x."+Language.getActiveColumn()+" == 'PHISICAL'") == 0;

            if(!isThirdTypesTranslated) {
            	translations.push('global.thirds.literals.phisical');
            	translations.push('global.thirds.literals.legal');
            	translations.push('global.thirds.literals.personwithoutlegalpersonality');
            }

            var isDocMouslesTranslated = $linq(factory.docModules).count("x => x.id == 'DOC' && x."+Language.getActiveColumn()+" == 'DOC'") == 0;

            if(!isDocMouslesTranslated) {
            	translations.push('global.literals.documents');
            	translations.push('global.literals.trams');
            	translations.push('global.literals.thirds');
            	translations.push('global.literals.register_entry_input');
            	translations.push('global.literals.register_entry_output');
            	translations.push('global.sec.toptitle');
            }
            
            if(translations.length != 0) {
            	promises.push(Language.getAllTranslationsMulti(translations));
            }

            if(!factory.notificationTypes || factory.notificationTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=NotificationType')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=NotificationType');
            		if(cache && cache.length > 0){
            			factory.notificationTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.notificationTypes || factory.notificationTypes.length == 0) {
                    promises.push(RestService.findAll('NotificationType', undefined));
            	}
            }
            
            if(!factory.notificationStatuses || factory.notificationStatuses.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=NotificationStatus')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=NotificationStatus');
            		if(cache && cache.length > 0){
            			factory.notificationStatuses = JSOG.decode(angular.fromJson(cache));
            			if(factory.notificationStatuses && factory.notificationStatuses.length > 0){
	            			factory.notificationStatuses = $linq(factory.notificationStatuses).orderBy("x => x." + Language.getActiveColumn())
								.distinctBy("x => x." + Language.getActiveColumn(), "(x, y) => x.toLowerCase() == y.toLowerCase()").toArray();
            			}
            		}
            	}
            	if(!factory.notificationStatuses || factory.notificationStatuses.length == 0) {
                    promises.push(RestService.findAll('NotificationStatus', undefined));
            	}
            }

            if(!factory.languages || factory.languages.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('languages')){
            		var cache = CacheFactory.get('globalDataCache').get('languages');
            		if(cache && cache.length > 0){
            			factory.languages = JSOG.decode(angular.fromJson(cache));
            			if($rootScope.app.languagedef) {
	                        $rootScope.app.languagedef.length = 0;
	                    }else{
	                        $rootScope.app.languagedef = [];
	                    }
            			_.forEach(factory.languages,function(language) {
	                         $rootScope.app.languagedef.push(new LanguageDef(language.acronym, language.name, language.column, $rootScope.LoggedUser.language.acronym == language.acronym));
	                    });
            			localStorage.setItem("languagedef", JSON.stringify(JSOG.encode($rootScope.app.languagedef)));
						if($rootScope.LoggedUser.language && $rootScope.LoggedUser.language.acronym){
						    $rootScope.app.language = $rootScope.LoggedUser.language.acronym;
						    Language.setCurrent($rootScope.LoggedUser.language.acronym);
						    $rootScope.app.langColumn = Language.getActiveColumn();
						    $translate.preferredLanguage($rootScope.LoggedUser.language.acronym);
                            tmhDynamicLocale.set($rootScope.LoggedUser.language.acronym);
                            if($translate.use() != $rootScope.LoggedUser.language.acronym){
                            	callTranslate = true;
                            }
						}
						factory.emgdeLists.secundaryValues = [
							{id: "Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.yes')},
			               	{id: "Valor testimonial: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes1'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes1')},
			               	{id: "Valor informativo: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes2'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes2')},
			               	{id: "Valor histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes3'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes3')},
			               	{id: "Valor testimonial o informativo: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes4'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes4')},
			               	{id: "Valor testimonial o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes5'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes5')},
			               	{id: "Valor informativo o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes6'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes6')},
			               	{id: "Valor testimonial, informativo o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes7'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes7')},
			               	{id: "No", description: $filter('translate')('global.documents.EMGDE.secundaryValues.no'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.no')},
			               	{id: "Sin cobertura de calificación", description: $filter('translate')('global.documents.EMGDE.secundaryValues.without'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.without')}
			            ];
		                
            		}
            	}
            	if(!factory.languages || factory.languages.length == 0){
            		promises.push(RestService.findAll('Language', undefined));
            	}
            }
            
            if(!factory.configuration || factory.configuration.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
            		var cache = CacheFactory.get('globalDataCache').get('configuration');
            		if(cache && cache.length > 0){
            			factory.configuration = JSOG.decode(angular.fromJson(cache));
            			_.forEach(factory.configuration, function (conf) {
	                         $rootScope.app.configuration[conf.key] = JSON.parse(conf.value);
	                     });
	
	                     if($rootScope.app.configuration.eset.value) {
	                         $rootScope.app.configuration.show_styled_document_type_acronym.value = false;
	                         $rootScope.app.configuration.add_document_type_and_creation_date_to_document_name.value = true;
	                         $rootScope.esetMode = true;
	                     }
	                     
	                     //Elimina el "OTHER" que no se debe mostrar de la lista de tipos de documentos en funcion de esetMode  
	                     var lstIdentificationDocumentTypes = factory.thirdIdentificationDocumentTypes;
	                     lstIdentificationDocumentTypes.forEach( function(item, index, object) {
	                    	 if ($rootScope.esetMode && item.id == 'OTHER' && item.language1 == 'OTHER') {
	                    		 object.splice(index, 1);
	                    	 } else if (!$rootScope.esetMode && item.id == 'OTHER' && item.language1 == 'PASSPORT') {
	                    		 object.splice(index, 1);
	                    	 }
	                     });
	
	                     if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.app_version && $rootScope.app.configuration.app_version.value){
	                         $rootScope.app.version = $rootScope.app.configuration.app_version.value;
	                     }
	                     if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.default_office && !$rootScope.app.configuration.multiple_register_office.active) {
	                         var defaultRegisterEntryOffice = $linq(factory.registerEntryOffices).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.multiple_register_office.default_office);
	                         if(defaultRegisterEntryOffice) {
	                        	 factory.registerEntryOffices = [defaultRegisterEntryOffice];
	                        	 factory.defaultRegisterEntryOffice = defaultRegisterEntryOffice;
	                         } else {
	                        	 factory.registerEntryOffices = [];
	                         }
	                     }else{
	                    	 if(factory.registerEntryOffices.length == 1) {
	                    		 factory.defaultRegisterEntryOffice = factory.registerEntryOffices[0];
	                         }
	                     }
	                     $rootScope.$broadcast('ModifySelectedTypeGlobalDataCache');
	                     configChanged = true;
            		}
            	}
            	if(!factory.configuration || factory.configuration.length == 0){
            		promises.push(RestService.findAll('Configuration', 'login'));
            	}
            }
            
            if(!factory.pluginUibutton || factory.pluginUibutton.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('pluginUibutton')){
            		var cache = CacheFactory.get('globalDataCache').get('pluginUibutton');
            		if(cache && cache.length > 0){
            			factory.pluginUibutton = JSOG.decode(angular.fromJson(cache));
            			$rootScope.app.UIButtons = factory.pluginUibutton;
            		}
            	}
            	if(!factory.pluginUibutton || factory.pluginUibutton.length == 0) {
                    promises.push($http({url: './api/plugin/uibutton/list', method: 'GET'}));
            	}
            }
            
            if(!factory.menuWithPermissions || factory.menuWithPermissions.length == 0) {
            	_.forEach($rootScope.allModules, function(module) {
                    var perms = $linq(module.actionPermissions).select("x => x.keyId").toArray();
                    switch(module.url) {
                        case 'annexa.reg.input':
                            perms.push('diligence_input_register');
                            break;
                        case 'annexa.reg.output':
                            perms.push('view_notifications');
                            break;
                        case 'annexa.tasks':
                            perms.push('view_tasks');
                            break;
                        case 'annexa.bi':
                        case 'annexa.bi.user':
                            perms.push('bi_user');
                        case 'annexa.sign':
                        case 'annexa.sign.pending':
                            perms.push('sign');
                    }
                    module.permissions = perms;
                    factory.menuWithPermissions.push(module);
                });
            	if($rootScope.app.menu) {
                     if(Array.isArray($rootScope.app.menu)){
                         $rootScope.app.menu.length = 0;
                     }else{
                         $rootScope.app.menu = [];
                     }
                     if(factory.menuWithPermissions){ 
                         _.forEach(factory.menuWithPermissions, function(value){
                             $rootScope.app.menu.push(value);
                         });
                     }
                 }else{
                     if(factory.menuWithPermissions){
                         $rootScope.app.menu = factory.menuWithPermissions;
                     }else{
                         $rootScope.app.menu = [];
                     }
                 }
    			try {
                	CacheFactory.get("globalDataCache").put('menuWithPermissions',factory.menuWithPermissions);
                } catch (error) {
        			console.error(error);
        		}
                 $rootScope.$broadcast('printAnnexaMenu', {menu:$rootScope.app.menu});
            }
            
            if(!factory.footerClaims || factory.footerClaims.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('footerClaims')){
            		var cache = CacheFactory.get('globalDataCache').get('footerClaims');
            		if(cache && cache.length > 0){
            			factory.footerClaims = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.footerClaims || factory.footerClaims.length == 0) {
                    promises.push(RestService.findAll('FooterClaim'));
            	}
            }
            
            if(!factory.roleInteresteds || factory.roleInteresteds.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('roleInteresteds')){
            		var cache = CacheFactory.get('globalDataCache').get('roleInteresteds');
            		if(cache && cache.length > 0){
            			factory.roleInteresteds = JSOG.decode(angular.fromJson(cache));
            			if (factory.roleInteresteds && factory.roleInteresteds.length > 0) {
                        	$rootScope.existsOptionsRoleInterested = true;
                        } else {
                        	$rootScope.existsOptionsRoleInterested = false;
                        }
            		}
            	}
            	if(!factory.roleInteresteds || factory.roleInteresteds.length == 0) {
                    promises.push(RestService.findAll('RoleInterested'));
            	}
            }
            
            if(!factory.proposalTypes || factory.proposalTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('proposalTypes')){
            		var cache = CacheFactory.get('globalDataCache').get('proposalTypes');
            		if(cache && cache.length > 0){
            			factory.proposalTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.proposalTypes || factory.proposalTypes.length == 0) {
                    promises.push(RestService.findAll('ProposalType'));
            	}
            }
            
            if(!factory.decreeDelegatedTypes || factory.decreeDelegatedTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('decreeDelegatedTypes')){
            		var cache = CacheFactory.get('globalDataCache').get('decreeDelegatedTypes');
            		if(cache && cache.length > 0){
            			factory.decreeDelegatedTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.decreeDelegatedTypes || factory.decreeDelegatedTypes.length == 0) {
                    promises.push(RestService.findAll('DecreeDelegatedType'));
            	}
            }
            
            if(!factory.signstatestypes || factory.signstatestypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('signstatestypes')){
            		var cache = CacheFactory.get('globalDataCache').get('signstatestypes');
            		if(cache && cache.length > 0){
            			factory.signstatestypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.signstatestypes || factory.signstatestypes.length == 0) {
                    promises.push($http({url: './api/localdata?store=signstatestypes', method: 'GET'}));
            	}
            }
            
            if(!factory.transactiontypes || factory.transactiontypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('transactiontypes')){
            		var cache = CacheFactory.get('globalDataCache').get('transactiontypes');
            		if(cache && cache.length > 0){
            			factory.transactiontypes = JSOG.decode(angular.fromJson(cache));
            			if(factory.transactiontypes && factory.transactiontypes.length > 0){
            				if($rootScope.app.configuration.transaction_types_order && $rootScope.app.configuration.transaction_types_order.alphabetically) {
            					factory.transactiontypes = $linq(factory.transactiontypes).orderBy("x => x."+Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            				} else {
                                factory.transactiontypes = $linq(factory.transactiontypes).orderBy("x => x.orderView").toArray();
            				}
                        }
            		}
            	}
            	if(!factory.transactiontypes || factory.transactiontypes.length == 0) {
                    promises.push($http({url: './api/transactiontypes/types', method: 'GET'}));
            	}
            }
            
            if(!factory.tramitationPhase || factory.tramitationPhase.length == 0) {
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('tramitationPhase')){
                    var cache = CacheFactory.get('globalDataCache').get('tramitationPhase');
                    if(cache && cache.length > 0){
                        factory.tramitationPhase = JSOG.decode(angular.fromJson(cache));
                    }
                }
                if(!factory.tramitationPhase || factory.tramitationPhase.length == 0) {
                    promises.push($http({url: './api/tramitationPhases/phases', method: 'GET'}));
                }
            }
            
            
            if(!factory.dossierTramitationTypes || factory.dossierTramitationTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('dossierTramitationTypes')){
            		var cache = CacheFactory.get('globalDataCache').get('dossierTramitationTypes');
            		if(cache && cache.length > 0){
            			factory.dossierTramitationTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.dossierTramitationTypes || factory.dossierTramitationTypes.length == 0) {
                    promises.push($http({url: './api/tramitationTypes/types', method: 'GET'}));
            	}
            }
            
            if(!factory.taskTemplates || factory.taskTemplates.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('taskTemplates')){
            		var cache = CacheFactory.get('globalDataCache').get('taskTemplates');
            		if(cache && cache.length > 0){
            			factory.taskTemplates = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.taskTemplates || factory.taskTemplates.length == 0) {
                    promises.push($http({url: './api/taskTemplates/taskTemplate', method: 'GET'}));
            	}
            }
            
            if(!factory.organs || factory.organs.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('organs')){
            		var cache = CacheFactory.get('globalDataCache').get('organs');
            		if(cache && cache.length > 0){
            			factory.organs = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.organs || factory.organs.length == 0) {
                    promises.push(RestService.findAll('Organ'));
            	}
            }
            
            if(!factory.sessionTypes || factory.sessionTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('sessionTypes')){
            		var cache = CacheFactory.get('globalDataCache').get('sessionTypes');
            		if(cache && cache.length > 0){
            			factory.sessionTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.sessionTypes || factory.sessionTypes.length == 0) {
                    promises.push(RestService.findAll('SessionType'));
            	}
            }
            
            if(!factory.secTemplates || factory.secTemplates.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('secTemplates')){
            		var cache = CacheFactory.get('globalDataCache').get('secTemplates');
            		if(cache && cache.length > 0){
            			factory.secTemplates = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.secTemplates || factory.secTemplates.length == 0) {
                    promises.push($http({ url: './api/sec/sec_template', method: 'GET' }));
            	}
            }
            
            if(!factory.organMemberRols || factory.organMemberRols.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('organMemberRols')){
            		var cache = CacheFactory.get('globalDataCache').get('organMemberRols');
            		if(cache && cache.length > 0){
            			factory.organMemberRols = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.organMemberRols || factory.organMemberRols.length == 0) {
                    promises.push(RestService.findAll('OrganMemberRol'));
            	}
            }
            
            if(!factory.meetingLocations || factory.meetingLocations.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('meetingLocations')){
            		var cache = CacheFactory.get('globalDataCache').get('meetingLocations');
            		if(cache && cache.length > 0){
            			factory.meetingLocations = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.meetingLocations || factory.meetingLocations.length == 0) {
                    promises.push(RestService.findAll('MeetingLocation'));
            	}
            }
            
            if(!factory.bookTypes || factory.bookTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('bookTypes')){
            		var cache = CacheFactory.get('globalDataCache').get('bookTypes');
            		if(cache && cache.length > 0){
            			factory.bookTypes = JSOG.decode(angular.fromJson(cache));
            			$rootScope.$broadcast('ModifyBookTypesGlobalDataCache');
            		}
            	}
            	if(!factory.bookTypes || factory.bookTypes.length == 0) {
                    promises.push(RestService.findAll('BookType'));
            	}
            }
            
            if(!factory.customFields || factory.customFields.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('customFields')){
            		var cache = CacheFactory.get('globalDataCache').get('customFields');
            		if(cache && cache.length > 0){
            			factory.customFields = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.customFields || factory.customFields.length == 0) {
                    promises.push(RestService.findAll('CustomField'));
            	}
            }
            
            if(!factory.publictramtemplates || factory.publictramtemplates.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('publictramtemplates')){
            		var cache = CacheFactory.get('globalDataCache').get('publictramtemplates');
            		if(cache && cache.length > 0){
            			factory.publictramtemplates = JSOG.decode(angular.fromJson(cache));
            			$rootScope.$broadcast('ModifyTramTemplatesGlobalDataCache');
            		}
            	}
            	if(!factory.publictramtemplates || factory.publictramtemplates.length == 0) {
                    promises.push($http({url: './api/tram/template/procedure',method: 'GET',params: {dossierId: -1, procedureId: -1}}));
            	}
            }
            
            if(!factory.diligenceStampTypes || factory.diligenceStampTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('diligenceStampTypes')){
            		var cache = CacheFactory.get('globalDataCache').get('diligenceStampTypes');
            		if(cache && cache.length > 0){
            			factory.diligenceStampTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.diligenceStampTypes || factory.diligenceStampTypes.length == 0) {
                    promises.push(RestService.findAll('DiligenceStampType'));
            	}
            }
            if(!factory.pseudoVies || factory.pseudoVies.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('PseudoVies')){
            		var cache = CacheFactory.get('globalDataCache').get('PseudoVia');
            		if(cache && cache.length > 0){
            			factory.pseudoVies = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.pseudoVies || factory.pseudoVies.length == 0) {
                    promises.push(RestService.findAll('PseudoVia', undefined));
            	}
            }

            if(!factory.addressTypes || factory.addressTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('AddressType')){
            		var cache = CacheFactory.get('globalDataCache').get('AddressType');
            		if(cache && cache.length > 0){
            			factory.addressTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.addressTypes || factory.addressTypes.length == 0) {
                    promises.push(RestService.findAll('AddressType', undefined));
            	}
            }

            if(!factory.operationTypes || factory.operationTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('OperationType')){
            		var cache = CacheFactory.get('globalDataCache').get('OperationType');
            		if(cache && cache.length > 0){
            			factory.operationTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.operationTypes || factory.operationTypes.length == 0) {
                    promises.push(RestService.findAll('OperationType'));
            	}
            }
            
            if(docTypesChanged && configChanged){
            	$rootScope.$broadcast('ModifyDocumentListGlobalDataCache');
            }
            
            if(!factory.relatedDossierRelations || factory.relatedDossierRelations.length == 0){
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('RelatedDossierRelation')){
            		var cache = CacheFactory.get('globalDataCache').get('RelatedDossierRelation');
            		if(cache && cache.length > 0){
            			factory.relatedDossierRelations = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.relatedDossierRelations || factory.relatedDossierRelations.length == 0) {
                    promises.push(RestService.findAll('RelatedDossierRelation', undefined));
            	}
            }
			if(!factory.userFilters || factory.userFilters.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('userFilters')){
            		var cache = CacheFactory.get('globalDataCache').get('userFilters');
            		if(cache && cache.length > 0){
                        factory.userFilters = new SortedArray(JSOG.decode(angular.fromJson(cache)), 'name').sort();
            		}
            	}
            	if(!factory.userFilters || factory.userFilters.length == 0){
            		promises.push($http({url: './api/table_filter/my_filters',method: 'GET'}));
            	}
            }
			if(!factory.userCountersConfiguration || factory.userCountersConfiguration.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('userCountersConfiguration')){
            		var cache = CacheFactory.get('globalDataCache').get('userCountersConfiguration');
            		if(cache && cache.length > 0){
                        factory.userCountersConfiguration = new SortedArray(JSOG.decode(angular.fromJson(cache)), 'name').sort();
            		}
            	}
            	if(!factory.userCountersConfiguration || factory.userCountersConfiguration.length == 0){
            		promises.push($http({ method: 'GET', url: './api/user_counter/my_counters' }));
				}
            }
			if(!factory.tramClassifications || factory.tramClassifications.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('tramClassifications')){
            		var cache = CacheFactory.get('globalDataCache').get('tramClassifications');
            		if(cache && cache.length > 0){
            			factory.tramClassifications = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.tramClassifications || factory.tramClassifications.length == 0) {
                    promises.push(RestService.findAll('TramClassification', undefined));
            	}
            }
			if(!factory.procedureClassifications || factory.procedureClassifications.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('procedureClassifications')){
            		var cache = CacheFactory.get('globalDataCache').get('procedureClassifications');
            		if(cache && cache.length > 0){
            			factory.procedureClassifications = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.procedureClassifications || factory.procedureClassifications.length == 0) {
                    promises.push(RestService.findAll('ProcedureClassification', undefined));
            	}
            }
			if(!factory.spelFunctions || factory.spelFunctions.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('spelFunctions')){
            		var cache = CacheFactory.get('globalDataCache').get('spelFunctions');
            		if(cache && cache.length > 0){
            			factory.spelFunctions = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.spelFunctions || factory.spelFunctions.length == 0) {
                    promises.push(RestService.findAll('SpelFunction', undefined));
            	}
            }
            if(!factory.identificationReferenceTypes || factory.identificationReferenceTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('identificationReferenceTypes')){
            		var cache = CacheFactory.get('globalDataCache').get('identificationReferenceTypes');
            		if(cache && cache.length > 0){
            			factory.identificationReferenceTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.identificationReferenceTypes || factory.identificationReferenceTypes.length == 0) {
                    promises.push(RestService.findAll('IdentificationReferenceType'));
            	}
            }
            if(!factory.genders || factory.genders.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('genders')){
            		var cache = CacheFactory.get('globalDataCache').get('genders');
            		if(cache && cache.length > 0){
            			factory.genders = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.genders || factory.genders.length == 0) {
                    promises.push(RestService.findAll('Gender'));
            	}
            }
            if(!factory.treatments || factory.treatments.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('treatments')){
            		var cache = CacheFactory.get('globalDataCache').get('treatments');
            		if(cache && cache.length > 0){
            			factory.treatments = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.treatments || factory.treatments.length == 0) {
                    promises.push(RestService.findAll('Treatment'));
            	}
            }
            if(!factory.relationshipLanguages || factory.relationshipLanguages.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('relationshipLanguages')){
            		var cache = CacheFactory.get('globalDataCache').get('relationshipLanguages');
            		if(cache && cache.length > 0){
            			factory.relationshipLanguages = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.relationshipLanguages || factory.relationshipLanguages.length == 0) {
                    promises.push(RestService.findAll('RelationshipLanguage'));
            	}
            }
            if(!factory.remittanceTypes || factory.remittanceTypes.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RemittanceType')){
            		var cache = CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RemittanceType');
            		if(cache && cache.length > 0){
            			factory.remittanceTypes = JSOG.decode(angular.fromJson(cache));
            		}
            	}
            	if(!factory.remittanceTypes || factory.remittanceTypes.length == 0) {
                    promises.push(RestService.findAll('RemittanceType', undefined));
            	}
            }
            if(!factory.councillors || factory.councillors.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('Councillors')){
            		var cache = CacheFactory.get('globalDataCache').get('Councillors');
            		if(cache && cache.length > 0){
           	    		factory.councillors = JSOG.decode(angular.fromJson(cache));
            			factory.councillors = $linq(factory.councillors).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
           	    		factory.councillorsTree = CommonService.getTreeData(angular.copy(factory.councillors), Language.getActiveColumn());
            		}
            	}
            	if(!factory.councillors || factory.councillors.length == 0) {
                    promises.push(RestService.findAll('Councillor', undefined));
            	}
            }
            if(!factory.procedureProposalType || factory.procedureProposalType.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('ProcedureProposalType')){
            		var cache = CacheFactory.get('globalDataCache').get('ProcedureProposalType');
            		if(cache && cache.length > 0){
           	    		factory.procedureProposalType = JSOG.decode(angular.fromJson(cache));
            			factory.procedureProposalType = $linq(factory.procedureProposalType).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            		}
            	}
            	if(!factory.procedureProposalType || factory.procedureProposalType.length == 0) {
                    promises.push(RestService.findAll('ProcedureProposalType', undefined));
            	}
            }
            if(!factory.publishSite || factory.publishSite.length == 0) {
            	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('PublishSite')){
            		var cache = CacheFactory.get('globalDataCache').get('PublishSite');
            		if(cache && cache.length > 0){
           	    		factory.publishSite = JSOG.decode(angular.fromJson(cache));
            			factory.publishSite = $linq(factory.publishSite).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            		}
            	}
            	if(!factory.publishSite || factory.publishSite.length == 0) {
                    promises.push(RestService.findAll('PublishSite', undefined));
            	}
            }
            $q.all(promises)
                .then(function(data) {
                    var dataPos = 0;
                    if(data && data.length > 0){
	                    if(!factory.documentTypes || factory.documentTypes.length == 0) {
	                        factory.documentTypes = new SortedArray($linq(fixDocumentTypes(JSOG.decode(data[dataPos].data))).where("x => x.isPublic").toArray(), Language.getActiveColumn()).sort();
	                        factory.allDocumentTypes = new SortedArray(fixDocumentTypes(JSOG.decode(data[dataPos].data)), Language.getActiveColumn()).sort();
	                        docTypesChanged = true;
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=DocumentType',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.templateTypes || factory.templateTypes.length == 0) {
	                        factory.templateTypes = JSOG.decode(data[dataPos].data);
	                        factory.templateTree = factory.templateTypes;
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=TemplateType',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.templates || factory.templates.length == 0) {
	                        factory.templates = JSOG.decode(data[dataPos].data);
	
	                        _.forEach(angular.copy(factory.templates), function(value) {
	                            value.parent = value.templateType;
	                            value.idOriginal = value.id;
	                            value.id = '-'+value.id;
	                            value[Language.getActiveColumn()] = value.name;
	                            factory.templateTree.push(value);
	                        });
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=Template',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.archiveClassifications || factory.archiveClassifications.length == 0) {
	                        factory.archiveClassifications = JSOG.decode(data[dataPos].data);
	                        if(factory.archiveClassifications && factory.archiveClassifications.length > 0){
	            				factory.archiveClassificationsTree = CommonService.getTreeData(factory.archiveClassifications, Language.getActiveColumn());
	            			}
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=ArchiveClassification',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.documentViewModeTypes || factory.documentViewModeTypes.length == 0) {
	                        factory.documentViewModeTypes = JSOG.decode(data[dataPos].data);
	                        if(factory.documentViewModeTypes.length == 0) {
	                            factory.documentViewModeTypes.push('empty');
	                        }
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=documentViewModeType',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.sections || factory.sections.length == 0) {
	                        factory.sections = JSOG.decode(data[dataPos].data);
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=Section',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.modules || factory.modules.length == 0) {
	                        factory.modules = JSOG.decode(data[dataPos].data);
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=Module',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                        var AnnexaPendingCounterFactory = angular.element(document.body).injector().get('AnnexaPendingCounterFactory');
	                        AnnexaPendingCounterFactory.updateModuleCounters();
	                        AnnexaPendingCounterFactory.runCounters();
	                    }
	
	                    if(!factory.signActionTypes || factory.signActionTypes.length == 0) {
	                        factory.signActionTypes = JSOG.decode(data[dataPos].data);
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=SignActionType',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.registerDiligenceTypes || factory.registerDiligenceTypes.length == 0) {
	                        factory.registerDiligenceTypes = JSOG.decode(data[dataPos].data);
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=RegisterDiligenceType',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.publishPlugins || factory.publishPlugins.length == 0) {
	                        factory.publishPlugins = JSOG.decode(data[dataPos].data);
	                        factory.publishList = $linq(factory.publishPlugins).select(function (x) { return { id: x.target, name: x.name}; }).toArray();
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/plugin/publish/list',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.allProfiles || factory.allProfiles.length == 0) {
	                        factory.allProfiles = JSOG.decode(data[dataPos].data);
	                        if(factory.allProfiles && factory.allProfiles.length > 0){
	                        	factory.allProfiles = new SortedArray(factory.allProfiles, Language.getActiveColumn()).sort();
	                        }
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=Profile&origin=allProfiles',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.registerEntryOffices || factory.registerEntryOffices.length == 0) {
	                        var haveProfile = function (profiles) {
	                            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
	                            userProfiles = $linq(userProfiles).distinctBy("x => x.id").toArray();
	
	                            profiles = Array.isArray(profiles) ? profiles : [profiles];
	
	                            var intersectedProfiles = $linq(userProfiles).intersect(profiles, "(x,y) => x.id == y.id").toArray();
	
	                            return intersectedProfiles.length != 0;
	                        };
	
	                        factory.registerEntryOffices = [];
	
	                        var regEntryOffices = JSOG.decode(data[dataPos].data);
	
	                        _.forEach(regEntryOffices, function (item) {
	                            var registerEntryOfficeProfiles = [];
	
	                            _.forEach(item.profiles, function (value) {
	                                registerEntryOfficeProfiles.push(value.profile);
	                            });
	
	                            if(registerEntryOfficeProfiles.length == 0 || haveProfile(registerEntryOfficeProfiles)) {
	                                factory.registerEntryOffices.push(item);
	                            }
	                        });
	                        
	                        factory.registerEntryOffices = $linq(factory.registerEntryOffices).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=RegisterEntryOffice&origin=registerEntryOffices',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.registerEntryChannelsInput || factory.registerEntryChannelsInput.length == 0){
	                        factory.registerEntryChannelsInput = JSOG.decode(data[dataPos].data);
	                        factory.registerEntryChannelsInput = $linq(factory.registerEntryChannelsInput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/reg/register_entry_channel/entry_type/INPUT',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.allRegisterEntryChannelsInput || factory.allRegisterEntryChannelsInput.length == 0){
	                        factory.allRegisterEntryChannelsInput = JSOG.decode(data[dataPos].data);
	                        factory.allRegisterEntryChannelsInput = $linq(factory.allRegisterEntryChannelsInput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/reg/register_entry_channel/entry_type/all/INPUT',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.registerEntryChannelsOutput || factory.registerEntryChannelsOutput.length == 0){
	                        factory.registerEntryChannelsOutput = JSOG.decode(data[dataPos].data);
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/reg/register_entry_channel/entry_type/OUTPUT',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.allRegisterEntryChannelsOutput || factory.allRegisterEntryChannelsOutput.length == 0){
	                        factory.allRegisterEntryChannelsOutput = JSOG.decode(data[dataPos].data);
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/reg/register_entry_channel/entry_type/all/OUTPUT',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.registerEntryDocumentTypesInput || factory.registerEntryDocumentTypesInput.length == 0) {
	                        factory.registerEntryDocumentTypesInput = JSOG.decode(data[dataPos].data);
	                        factory.registerEntryDocumentTypesInput = $linq(factory.registerEntryDocumentTypesInput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/reg/register_entry_document_type/entry_type/INPUT',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.registerEntryDocumentTypesOutput || factory.registerEntryDocumentTypesOutput.length == 0) {
	                        factory.registerEntryDocumentTypesOutput = JSOG.decode(data[dataPos].data);
	                        factory.registerEntryDocumentTypesOutput = $linq(factory.registerEntryDocumentTypesOutput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/reg/register_entry_document_type/entry_type/OUTPUT',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.registerEntryClassificationsInput || factory.registerEntryClassificationsInput.length == 0) {
	                        factory.registerEntryClassificationsInput = JSOG.decode(data[dataPos].data);
	                        factory.registerEntryClassificationsInput = $linq(factory.registerEntryClassificationsInput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/reg/register_entry_classification/entry_type/INPUT',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.registerEntryClassificationsOutput || factory.registerEntryClassificationsOutput.length == 0) {
	                        factory.registerEntryClassificationsOutput = JSOG.decode(data[dataPos].data);
	                        factory.registerEntryClassificationsOutput = $linq(factory.registerEntryClassificationsOutput).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/reg/register_entry_classification/entry_type/OUTPUT',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.familyProcedures || factory.familyProcedures.length == 0) {
	                        factory.familyProcedures = JSOG.decode(data[dataPos].data);
	            			factory.familyProcedures = $linq(factory.familyProcedures).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	            			factory.familyProceduresTree = CommonService.getTreeData(angular.copy(factory.familyProcedures), Language.getActiveColumn());
	            			try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=FamilyProcedure',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.procedures || factory.procedures.length == 0) {
	                        factory.procedures = JSOG.decode(data[dataPos].data);
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=Procedure&origin=globalData',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!factory.presetSignCircuit ||  factory.presetSignCircuit.length == 0 ){
	                        factory.presetSignCircuit = JSOG.decode(data[dataPos].data);
	                        if(factory.presetSignCircuit && factory.presetSignCircuit.length > 0){
		            			factory.presetSignCircuit = $linq(factory.presetSignCircuit).where("x => !x.user || !x.user.id").orderBy(function(x) {
		                            if(x[Language.getActiveColumn()]) {
		                                return x[Language.getActiveColumn()].toUpperCase();
		                            }
		
		                            return '';
		                        }).toArray();
	            			}
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=PresetSignCircuit',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	
	                    if(!isThirdIdentificationDocumentTypesTranslated) {
	                        var indexPassport = $linq(factory.thirdIdentificationDocumentTypes).indexOf("x => x.id == 'PASSPORT'");
	
	                        if(indexPassport != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.thirds.literals.passport'");
	                        	if(translation) {
		                            factory.thirdIdentificationDocumentTypes[indexPassport].language1 = translation.language1;
		                            factory.thirdIdentificationDocumentTypes[indexPassport].language2 = translation.language2;
		                            factory.thirdIdentificationDocumentTypes[indexPassport].language3 = translation.language3;
	                        	}
	                        }
	
	                        var indexOther = $linq(factory.thirdIdentificationDocumentTypes).indexOf("x => x.id == 'OTHER'");
	
	                        if(indexOther != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.literals.other'");
	                        	if(translation) {
	                        		factory.thirdIdentificationDocumentTypes[indexOther].language1 = translation.language1;
	                        		factory.thirdIdentificationDocumentTypes[indexOther].language2 = translation.language2;
	                        		factory.thirdIdentificationDocumentTypes[indexOther].language3 = translation.language3;
	                        	}
	                        }
	
	                    }
	
	                    if(!isThirdTypesTranslated) {
	                        var indexPhisical = $linq(factory.thirdTypes).indexOf("x => x.id == 'PHISICAL'");

	                        if(indexPhisical != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.thirds.literals.phisical'");
	                        	if(translation) {
	                        		factory.thirdTypes[indexPhisical].language1 = translation.language1;
	                        		factory.thirdTypes[indexPhisical].language2 = translation.language2;
	                        		factory.thirdTypes[indexPhisical].language3 = translation.language3;
	                        	}
	                        }
	
	                        var indexLegal = $linq(factory.thirdTypes).indexOf("x => x.id == 'LEGAL'");

	                        if(indexLegal != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.thirds.literals.legal'");
	                        	if(translation) {
		                            factory.thirdTypes[indexLegal].language1 = translation.language1;
		                            factory.thirdTypes[indexLegal].language2 = translation.language2;
		                            factory.thirdTypes[indexLegal].language3 = translation.language3;
	                        	}
	                        }
	
	                        var indexPersonalWithoutLegalPersonality = $linq(factory.thirdTypes).indexOf("x => x.id == 'PERSONWITHOUTLEGALPERSONALITY'");
	                        
	                        if(indexPersonalWithoutLegalPersonality != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.thirds.literals.personwithoutlegalpersonality'");
	                        	if(translation) {
		                            factory.thirdTypes[indexPersonalWithoutLegalPersonality].language1 = translation.language1;
		                            factory.thirdTypes[indexPersonalWithoutLegalPersonality].language2 = translation.language2;
		                            factory.thirdTypes[indexPersonalWithoutLegalPersonality].language3 = translation.language3;
	                        	}
	                        }
	
	                    }
	
	                    if(!isDocMouslesTranslated) {
	                        var indexDoc = $linq(factory.docModules).indexOf("x => x.id == 'DOC'");

	                        if(indexDoc != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.literals.documents'");
	                        	if(translation) {
		                            factory.docModules[indexDoc].language1 = translation.language1;
		                            factory.docModules[indexDoc].language2 = translation.language2;
		                            factory.docModules[indexDoc].language3 = translation.language3;
	                        	}
	                        }
	
	                        var indexExped = $linq(factory.docModules).indexOf("x => x.id == 'EXPED'");

	                        if(indexExped != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.literals.trams'");
	                        	if(translation) {
		                            factory.docModules[indexExped].language1 = translation.language1;
		                            factory.docModules[indexExped].language2 = translation.language2;
		                            factory.docModules[indexExped].language3 = translation.language3;
	                        	}
	                        }
	
	                        var indexThirds = $linq(factory.docModules).indexOf("x => x.id == 'THIRDS'");

	                        if(indexThirds != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.literals.thirds'");
	                        	if(translation) {
		                            factory.docModules[indexThirds].language1 = translation.language1;
		                            factory.docModules[indexThirds].language2 = translation.language2;
		                            factory.docModules[indexThirds].language3 = translation.language3;
	                        	}
	                        }
	
	                        var indexRegEntrada = $linq(factory.docModules).indexOf("x => x.id == 'REGENTRADA'");

	                        if(indexRegEntrada != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.literals.register_entry_input'");
	                        	if(translation) {
		                            factory.docModules[indexRegEntrada].language1 = translation.language1;
		                            factory.docModules[indexRegEntrada].language2 = translation.language2;
		                            factory.docModules[indexRegEntrada].language3 = translation.language3;
	                        	}
	                        }
	
	                        var indexRegSalida = $linq(factory.docModules).indexOf("x => x.id == 'REGSALIDA'");

	                        if(indexRegSalida != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.literals.register_entry_output'");
	                        	if(translation) {
		                            factory.docModules[indexRegSalida].language1 = translation.language1;
		                            factory.docModules[indexRegSalida].language2 = translation.language2;
		                            factory.docModules[indexRegSalida].language3 = translation.language3;
	                        	}
	                        }
	
	                        var indexSec = $linq(factory.docModules).indexOf("x => x.id == 'SEC'");

	                        if(indexSec != -1) {
	                        	var translation = $linq(data[dataPos]).firstOrDefault(undefined, "x => x.id == 'global.sec.toptitle'");
	                        	if(translation) {
		                            factory.docModules[indexSec].language1 = translation.language1;
		                            factory.docModules[indexSec].language2 = translation.language2;
		                            factory.docModules[indexSec].language3 = translation.language3;
	                        	}
	                        }
	
	                    }
	                    
	                    if(translations && translations.length != 0) {
	                    	dataPos++;
	                    }
	
	                    if(!factory.notificationTypes || factory.notificationTypes.length == 0) {
	                        factory.notificationTypes = JSOG.decode(data[dataPos].data);
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=NotificationType',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.notificationStatuses || factory.notificationStatuses.length == 0) {
	                        factory.notificationStatuses = JSOG.decode(data[dataPos].data);
	            			if(factory.notificationStatuses && factory.notificationStatuses.length > 0){
		            			factory.notificationStatuses = $linq(factory.notificationStatuses).orderBy("x => x." + Language.getActiveColumn())
									.distinctBy("x => x." + Language.getActiveColumn(), "(x, y) => x.toLowerCase() == y.toLowerCase()").toArray();
	            			}
	                        try {
	                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=NotificationStatus',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.languages || factory.languages.length == 0) {
	                        factory.languages = JSOG.decode(data[dataPos].data);
	                        if($rootScope.app.languagedef) {
		                        $rootScope.app.languagedef.length = 0;
		                    }else{
		                        $rootScope.app.languagedef = [];
		                    }
	            			_.forEach(factory.languages,function(language) {
		                         $rootScope.app.languagedef.push(new LanguageDef(language.acronym, language.name, language.column, $rootScope.LoggedUser.language.acronym == language.acronym));
		                    });
	            			localStorage.setItem("languagedef", JSON.stringify(JSOG.encode($rootScope.app.languagedef)));
							if($rootScope.LoggedUser.language && $rootScope.LoggedUser.language.acronym){
							    $rootScope.app.language = $rootScope.LoggedUser.language.acronym;
							    Language.setCurrent($rootScope.LoggedUser.language.acronym);
							    $rootScope.app.langColumn = Language.getActiveColumn();
							    $translate.preferredLanguage($rootScope.LoggedUser.language.acronym);
	                            tmhDynamicLocale.set($rootScope.LoggedUser.language.acronym);
	                            if($translate.use() != $rootScope.LoggedUser.language.acronym){
	                            	callTranslate = true;
	                            }
							}
	                        try {
	                        	CacheFactory.get("globalDataCache").put('languages',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.configuration || factory.configuration.length == 0) {
	                    	
	                    	factory.configuration = JSOG.decode(data[dataPos].data);
	            			_.forEach(factory.configuration, function (conf) {
		                         try{
									$rootScope.app.configuration[conf.key] = JSON.parse(conf.value);
								}catch(error){
									console.error(error);
									$rootScope.app.configuration[conf.key] = undefined;
								}
		                     });
		
		                     if($rootScope.app.configuration.eset.value) {
		                         $rootScope.app.configuration.show_styled_document_type_acronym.value = false;
		                         $rootScope.app.configuration.add_document_type_and_creation_date_to_document_name.value = true;
		                         $rootScope.esetMode = true;
		                     }
		                     
		                     //Elimina el "OTHER" que no se debe mostrar de la lista de tipos de documentos en funcion de esetMode  
		                     var lstIdentificationDocumentTypes = factory.thirdIdentificationDocumentTypes;
		                     lstIdentificationDocumentTypes.forEach( function(item, index, object) {
		                    	 if ($rootScope.esetMode && item.id == 'OTHER' && item.language1 == 'OTHER') {
		                    		 object.splice(index, 1);
		                    	 } else if (!$rootScope.esetMode && item.id == 'OTHER' && item.language1 == 'PASSPORT') {
		                    		 object.splice(index, 1);
		                    	 }
		                     });
		
		                     if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.app_version && $rootScope.app.configuration.app_version.value){
		                         $rootScope.app.version = $rootScope.app.configuration.app_version.value;
		                     }
		                     
		                     if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.default_office && !$rootScope.app.configuration.multiple_register_office.active) {
		                         var defaultRegisterEntryOffice = $linq(factory.registerEntryOffices).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.multiple_register_office.default_office);
		                         if(defaultRegisterEntryOffice) {
		                        	 factory.registerEntryOffices = [defaultRegisterEntryOffice];
		                        	 factory.defaultRegisterEntryOffice = defaultRegisterEntryOffice;
		                         } else {
		                        	 factory.registerEntryOffices = [];
		                         }
		                     }else{
		                    	 if(factory.registerEntryOffices.length == 1) {
		                    		 factory.defaultRegisterEntryOffice = factory.registerEntryOffices[0];
		                         }
		                     }
		                     $rootScope.$broadcast('ModifySelectedTypeGlobalDataCache');
		                     configChanged = true;
		                     
		                     try {
	                        	CacheFactory.get("globalDataCache").put('configuration',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.pluginUibutton || factory.pluginUibutton.length == 0) {
	                    	factory.pluginUibutton = JSOG.decode(data[dataPos].data);
	            			$rootScope.app.UIButtons = factory.pluginUibutton;
	            			try {
	                        	CacheFactory.get("globalDataCache").put('pluginUibutton',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.footerClaims || factory.footerClaims.length == 0) {
	                    	factory.footerClaims = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('footerClaims',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.roleInteresteds || factory.roleInteresteds.length == 0) {
	                    	factory.roleInteresteds = JSOG.decode(data[dataPos].data);
	                    	if (factory.roleInteresteds && factory.roleInteresteds.length > 0) {
	                        	$rootScope.existsOptionsRoleInterested = true;
	                        } else {
	                        	$rootScope.existsOptionsRoleInterested = false;
	                        }
	            			try {
	                        	CacheFactory.get("globalDataCache").put('roleInteresteds',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.proposalTypes || factory.proposalTypes.length == 0) {
	                    	factory.proposalTypes = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('proposalTypes',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.decreeDelegatedTypes || factory.decreeDelegatedTypes.length == 0) {
	                    	factory.decreeDelegatedTypes = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('decreeDelegatedTypes',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.signstatestypes || factory.signstatestypes.length == 0) {
	                    	factory.signstatestypes = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('signstatestypes',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.transactiontypes || factory.transactiontypes.length == 0) {
	                    	factory.transactiontypes = JSOG.decode(data[dataPos].data);
	                    	if(factory.transactiontypes && factory.transactiontypes.length > 0){
	                    		if($rootScope.app.configuration.transaction_types_order && $rootScope.app.configuration.transaction_types_order.alphabetically) {
	                    			factory.transactiontypes = $linq(factory.transactiontypes).orderBy("x => x."+Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	            				} else {
	                                factory.transactiontypes = $linq(factory.transactiontypes).orderBy("x => x.orderView").toArray();
	            				}
	                        }
	            			try {
	                        	CacheFactory.get("globalDataCache").put('transactiontypes',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    } 
	                    
                        if(!factory.tramitationPhase || factory.tramitationPhase.length == 0) {
                            factory.tramitationPhase = JSOG.decode(data[dataPos].data);
                            try {
                                CacheFactory.get("globalDataCache").put('tramitationPhase',data[dataPos].data);
                            } catch (error) {
                                console.error(error);
                            }
                            dataPos++;
                        }
	                    
	                    if(!factory.dossierTramitationTypes || factory.dossierTramitationTypes.length == 0) {
	                    	factory.dossierTramitationTypes = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('dossierTramitationTypes',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.taskTemplates || factory.taskTemplates.length == 0) {
	                    	factory.taskTemplates = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('taskTemplates',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.organs || factory.organs.length == 0) {
	                    	factory.organs = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('organs',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.sessionTypes || factory.sessionTypes.length == 0) {
	                    	factory.sessionTypes = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('sessionTypes',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.secTemplates || factory.secTemplates.length == 0) {
	                    	factory.secTemplates = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('secTemplates',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.organMemberRols || factory.organMemberRols.length == 0) {
	                    	factory.organMemberRols = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('organMemberRols',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.meetingLocations || factory.meetingLocations.length == 0) {
	                    	factory.meetingLocations = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('meetingLocations',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.bookTypes || factory.bookTypes.length == 0) {
	                    	factory.bookTypes = JSOG.decode(data[dataPos].data);
	                    	$rootScope.$broadcast('ModifyBookTypesGlobalDataCache');
	            			try {
	                        	CacheFactory.get("globalDataCache").put('bookTypes',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    
	                    if(!factory.customFields || factory.customFields.length == 0) {
	                    	factory.customFields = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('customFields',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    if(!factory.publictramtemplates || factory.publictramtemplates.length == 0) {
	                    	factory.publictramtemplates = JSOG.decode(data[dataPos].data);
	                    	$rootScope.$broadcast('ModifyTramTemplatesGlobalDataCache');
	            			try {
	                        	CacheFactory.get("globalDataCache").put('publictramtemplates',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    if(!factory.diligenceStampTypes || factory.diligenceStampTypes.length == 0) {
	                    	factory.diligenceStampTypes = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('diligenceStampTypes',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    if(!factory.pseudoVies || factory.pseudoVies.length == 0) {
	                    	factory.pseudoVies = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('PseudoVia',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    if(!factory.addressTypes || factory.addressTypes.length == 0) {
	                    	factory.addressTypes = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('AddressType',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    if($rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.secretary_profile){
	                   	 	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('secretaryProfile')){
	                    		var cache = CacheFactory.get('globalDataCache').get('secretaryProfile');
	                    		if(cache){
	                    			factory.secretaryProfile = JSOG.decode(angular.fromJson(cache));
	                    		}
	                    	}
	                    	if(!factory.secretaryProfile) {
	                    		factory.secretaryProfile = $linq(factory.allProfiles).firstOrDefault(undefined, "x => x.id == "+$rootScope.app.configuration.secretary_properties.secretary_profile);
	                    		RestService.findOne('Profile', $rootScope.app.configuration.secretary_properties.secretary_profile, 'tram_sec').then(function(dataAux) {
	                    			factory.secretaryProfile = JSOG.decode(dataAux.data);
	                    			try {
	                    				CacheFactory.get("globalDataCache").put('secretaryProfile',dataAux.data);
	                    			} catch (error) {
	                    				console.error(error);
	                       			}
		                    	 }).catch(function (error) {
		                    		 factory.secretaryProfile = undefined;
		                    	 });
	                    	}
	                    }else{
	                   	 	factory.secretaryProfile = undefined; 
	                    }
	                    if(!factory.operationTypes || factory.operationTypes.length == 0) {
	                    	factory.operationTypes = JSOG.decode(data[dataPos].data);
	            			try {
	                        	CacheFactory.get("globalDataCache").put('OperationType',data[dataPos].data);
	                        } catch (error) {
	                			console.error(error);
	                		}
	                        dataPos++;
	                    }
	                    if($rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.intervention_profile){
	                   	 	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('interventionProfile')){
	                    		var cache = CacheFactory.get('globalDataCache').get('interventionProfile');
	                    		if(cache){
	                    			factory.interventionProfile = JSOG.decode(angular.fromJson(cache));
	                    		}
	                    	}
	                    	if(!factory.interventionProfile) {
	                    		factory.interventionProfile = $linq(factory.allProfiles).firstOrDefault(undefined, "x => x.id == "+$rootScope.app.configuration.secretary_properties.intervention_profile);
	                    		RestService.findOne('Profile', $rootScope.app.configuration.secretary_properties.intervention_profile, 'tram_sec').then(function(dataAux) {
	                    			factory.interventionProfile = JSOG.decode(dataAux.data);
	                    			try {
	                    				CacheFactory.get("globalDataCache").put('interventionProfile',dataAux.data);
	                    			} catch (error) {
	                    				console.error(error);
	                       			}
		                    	 }).catch(function (error) {
		                    		 factory.interventionProfile = undefined;
		                    	 });
	                    	}
	                    }else{
	                   	 	factory.interventionProfile = undefined; 
	                    }
	                    if(docTypesChanged || configChanged){
	                    	$rootScope.$broadcast('ModifyDocumentListGlobalDataCache');
	                    }
                	}
                    if(!factory.relatedDossierRelations || factory.relatedDossierRelations.length == 0) {
                    	factory.relatedDossierRelations = JSOG.decode(data[dataPos].data);
            			try {
                        	CacheFactory.get("globalDataCache").put('RelatedDossierRelation',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
					if(!factory.userFilters || factory.userFilters.length == 0) {
                    	factory.userFilters = JSOG.decode(data[dataPos].data);
						if(factory.userFilters && factory.userFilters.length > 0){
							factory.userFilters = new SortedArray(factory.userFilters, 'name').sort();
						}
            			try {
                        	CacheFactory.get("globalDataCache").put('userFilters',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
					if(!factory.userCountersConfiguration || factory.userCountersConfiguration.length == 0) {
                    	factory.userCountersConfiguration = JSOG.decode(data[dataPos].data);
						if(factory.userCountersConfiguration && factory.userCountersConfiguration.length > 0){
							factory.userCountersConfiguration = new SortedArray(factory.userCountersConfiguration, 'name').sort();
						}
            			try {
                        	CacheFactory.get("globalDataCache").put('userCountersConfiguration',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
					if(!factory.tramClassifications || factory.tramClassifications.length == 0) {
                        factory.tramClassifications = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('tramClassifications',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
					if(!factory.procedureClassifications || factory.procedureClassifications.length == 0) {
                        factory.procedureClassifications = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('procedureClassifications',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
					if(!factory.spelFunctions || factory.spelFunctions.length == 0) {
                        factory.spelFunctions = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('spelFunctions',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                    if(!factory.genders || factory.identificationReferenceTypes.length == 0) {
                    	factory.identificationReferenceTypes = JSOG.decode(data[dataPos].data);
            			try {
                        	CacheFactory.get("globalDataCache").put('identificationReferenceTypes',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                    if(!factory.genders || factory.genders.length == 0) {
                    	factory.genders = JSOG.decode(data[dataPos].data);
            			try {
                        	CacheFactory.get("globalDataCache").put('genders',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                    if(!factory.treatments || factory.treatments.length == 0) {
                    	factory.treatments = JSOG.decode(data[dataPos].data);
            			try {
                        	CacheFactory.get("globalDataCache").put('treatments',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                    if(!factory.relationshipLanguages || factory.relationshipLanguages.length == 0) {
                    	factory.relationshipLanguages = JSOG.decode(data[dataPos].data);
            			try {
                        	CacheFactory.get("globalDataCache").put('relationshipLanguages',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                    if(!factory.remittanceTypes || factory.remittanceTypes.length == 0) {
                        factory.remittanceTypes = JSOG.decode(data[dataPos].data);
                        try {
                        	CacheFactory.get("globalDataCache").put('./api/general/findAll?object_type=RemittanceType',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                    if(!factory.councillors || factory.councillors.length == 0) {
           	    		factory.councillors = JSOG.decode(data[dataPos].data);
						factory.councillors = $linq(factory.councillors).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
           	    		factory.councillorsTree = CommonService.getTreeData(angular.copy(factory.councillors), Language.getActiveColumn());
		            	try {
                        	CacheFactory.get("globalDataCache").put('Councillors',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                    if(!factory.procedureProposalType || factory.procedureProposalType.length == 0) {
           	    		factory.procedureProposalType = JSOG.decode(data[dataPos].data);
						factory.procedureProposalType = $linq(factory.procedureProposalType).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
		            	try {
                        	CacheFactory.get("globalDataCache").put('ProcedureProposalType',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                    if(!factory.publishSite || factory.publishSite.length == 0) {
           	    		factory.publishSite = JSOG.decode(data[dataPos].data);
						factory.publishSite = $linq(factory.publishSite).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
		            	try {
                        	CacheFactory.get("globalDataCache").put('PublishSite',data[dataPos].data);
                        } catch (error) {
                			console.error(error);
                		}
                        dataPos++;
                    }
                    if(!callTranslate){
						factory.emgdeLists.secundaryValues = [
								{id: "Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.yes')},
				               	{id: "Valor testimonial: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes1'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes1')},
				               	{id: "Valor informativo: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes2'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes2')},
				               	{id: "Valor histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes3'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes3')},
				               	{id: "Valor testimonial o informativo: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes4'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes4')},
				               	{id: "Valor testimonial o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes5'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes5')},
				               	{id: "Valor informativo o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes6'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes6')},
				               	{id: "Valor testimonial, informativo o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes7'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes7')},
				               	{id: "No", description: $filter('translate')('global.documents.EMGDE.secundaryValues.no'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.no')},
				               	{id: "Sin cobertura de calificación", description: $filter('translate')('global.documents.EMGDE.secundaryValues.without'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.without')}
				        ];
                        deferrend.resolve("OK");
                    }else{
                    	$translate.use($rootScope.LoggedUser.language.acronym).then(function(){
                            $translate.refresh();
							factory.emgdeLists.secundaryValues = [
								{id: "Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.yes')},
				               	{id: "Valor testimonial: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes1'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes1')},
				               	{id: "Valor informativo: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes2'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes2')},
				               	{id: "Valor histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes3'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes3')},
				               	{id: "Valor testimonial o informativo: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes4'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes4')},
				               	{id: "Valor testimonial o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes5'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes5')},
				               	{id: "Valor informativo o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes6'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes6')},
				               	{id: "Valor testimonial, informativo o histórico: Sí", description: $filter('translate')('global.documents.EMGDE.secundaryValues.yes7'), descriptionAux: '&emsp;&emsp;'+$filter('translate')('global.documents.EMGDE.secundaryValues.yes7')},
				               	{id: "No", description: $filter('translate')('global.documents.EMGDE.secundaryValues.no'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.no')},
				               	{id: "Sin cobertura de calificación", description: $filter('translate')('global.documents.EMGDE.secundaryValues.without'), descriptionAux: $filter('translate')('global.documents.EMGDE.secundaryValues.without')}
				            ];
			                deferrend.resolve("OK");
                        }).catch(function(){
                        	deferrend.resolve("OK");
                        });
                    }
                }).catch(function (error) {
                    console.error(error);
                    factory.documentTypes = [];
                    factory.templateTypes = [];
                    factory.templates = [];
                    factory.archiveClassifications = [];
                    factory.documentViewModeTypes = [];
                    factory.sections = [];
                    factory.modules = [];
                    factory.signActionTypes = [];
                    factory.registerDiligenceTypes = [];
                    factory.allProfiles = [];
                    factory.registerEntryOffices = [];
                    factory.defaultRegisterEntryOffice = undefined;
                    factory.registerEntryChannelsInput = [];
                    factory.allRegisterEntryChannelsInput = [];
                    factory.registerEntryChannelsOutput = [];
                    factory.allRegisterEntryChannelsOutput = [];
                    factory.registerEntryDocumentTypesInput = [];
                    factory.registerEntryDocumentTypesOutput = [];
                    factory.registerEntryClassificationsInput = [];
                    factory.registerEntryClassificationsOutput = [];
                    factory.registerEntryDocumentTypesInput = [];
                    factory.registerEntryDocumentTypesOutput = [];
                    factory.registerEntryClassificationsInput = [];
                    factory.registerEntryClassificationsOutput = [];
                    factory.familyProcedures = [];
                    factory.familyProceduresTree = [];
                    factory.procedures = [];
                    factory.notificationTypes = [];
                    factory.remittanceTypes = [];
                    factory.notificationStatuses = [];
                    factory.presetSignCircuit = [];
                    factory.proposals = [];
                    factory.languages = [];
                    factory.configuration = [];
                    factory.pluginUibutton = [];
                    factory.menuWithPermissions = [];
                    factory.footerClaims = [];
                    factory.roleInteresteds = [];
                    $rootScope.existsOptionsRoleInterested = false;
                    factory.proposalTypes = []; 
                    factory.signstatestypes = [];
                    factory.transactiontypes = [];
                    factory.tramitationPhase = [];
                    factory.dossierTramitationTypes = [];
                    factory.taskTemplates = [];
                    factory.organs = [];
                    factory.sessionTypes = [];
                    factory.secTemplates = [];
                    factory.organMemberRols = [];
                    factory.meetingLocations = [];
                    factory.bookTypes = [];
                    factory.customFields = [];
                    factory.secretaryProfile = undefined;
                    factory.publictramtemplates = [];
                    factory.operationTypes = [];
                    factory.diligenceStampTypes = [];
                    factory.pseudoVies = [];
                    factory.addressTypes = [];
                    factory.relatedDossierRelations = [];
                    factory.decreeDelegatedTypes = [];
					factory.userFilters = [];
					factory.userCountersConfiguration = [];
					factory.tramClassifications = [];
					factory.procedureClassifications = [];
					factory.spelFunctions = [];
					factory.identificationReferenceTypes = [];
					factory.genders = [];
					factory.treatments = [];
					factory.relationshipLanguages = [];
					factory.councillors = [];
					factory.procedureProposalType = [];
			        factory.remittanceTypes = [];
                    deferrend.resolve("Error");
                });
            return deferrend.promise;
        };

        return factory;
    }]);

