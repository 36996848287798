/**
 * Created by osirvent on 15/02/2016.
 */
angular
    .module('annexaApp')
    .service('RestService', ['$q', '$http', '$filter', 'Language', 'DialogsFactory', '$rootScope', function($q, $http, $filter, Language, DialogsFactory, $rootScope) {
        this.getDataJson = function(url,filter) {
            var httpPromise = $http({
                url: url,
                method: "GET",
                params: filter
            }).success(function (response) {
                return angular.toJson(response,true);
            }).error(function(error){
                console.log(error);
            });

            return httpPromise;
        };

        this.filterData = function(origin, object_type, filter, aditional_filter,page,size,sort,jsonDepth, callOrigin, noLoading) {
            var request = {
                url: './api/general/filterdata',
                method: 'GET',
                params: {
                    origin: origin,
                    object_type: object_type,
                    filter: angular.fromJson(filter),
                    aditional_filter: aditional_filter,
                    page: page,
                    size: size,
                    order_by:sort,
                    jsonDepth: jsonDepth,
                    callOrigin: callOrigin }
            }

            if(noLoading) {
                request.headers = { noLoading: true }
            }

            var httpPromise = $http(request)
                .success(function(response) {
                    response.content = JSOG.decode(response.content);
                    return response;
                }).error(function(error) {
                    console.log(error);
            });

            return httpPromise;
        }
		
		this.filterById = function(origin, object_type, select_origin, select_type,select_id,page,size,sort,jsonDepth, callOrigin, noLoading){
            var request = {
                url: './api/general/filterById',
                method: 'GET',
                params: {
                    origin: origin,
                    object_type: object_type,
                    select_origin: select_origin,
                    select_type: select_type,
                    select_id: select_id,
					page: page,
                    size: size,
                    order_by:sort,
                    jsonDepth: jsonDepth,
                    callOrigin: callOrigin }
            }

            if(noLoading) {
                request.headers = { noLoading: true }
            }

            var httpPromise = $http(request)
                .success(function(response) {
                    response.content = JSOG.decode(response.content);
                    return response;
                }).error(function(error) {
                    console.log(error);
            });

            return httpPromise;			
		}

		this.exportData = function(tableDefinition) {
			if(tableDefinition){
				var sort = tableDefinition.actualOrder;
				if(!sort){
					if(tableDefinition.sortName){
						if(tableDefinition.sort && tableDefinition.sort.length > 0 && tableDefinition.sort[0] && tableDefinition.sort[0].length > 1){
							sort = tableDefinition.sortName+","+tableDefinition.sort[0][1];
						}
					}
					if(!sort && tableDefinition.sort && tableDefinition.sort.length > 0 && tableDefinition.sort[0] && tableDefinition.sort[0].length > 1){
						if(tableDefinition.columns && tableDefinition.columns.length > tableDefinition.sort[0][0]){
							sort = tableDefinition.columns[tableDefinition.sort[0][0]].id+","+tableDefinition.sort[0][1];	
						}
					}					
				}
				var filterCall = ((tableDefinition.filterCall)?tableDefinition.filterCall:((tableDefinition.filterCallFunc)?tableDefinition.filterCallFunc():{}));
				var filterCallAux = ((tableDefinition.filterCallAux)?tableDefinition.filterCallAux:((tableDefinition.filterCallAuxFunc)?tableDefinition.filterCallAux():{}));
				if(tableDefinition.origin && tableDefinition.objectType){
		            var request = {
		                url: './api/general/exportData',
		                method: 'GET',
		                params: {
		                    origin: tableDefinition.origin,
		                    object_type: tableDefinition.objectType,
		                    filter: angular.fromJson(filterCall),
		                    aditional_filter: filterCallAux,
		                    order_by:((sort)?sort:''),
		                    callOrigin: tableDefinition.callOrigin 
						},
		             	headers: {
		                	accept: 'application/csv'
		                },
		                responseType: 'arraybuffer',
		                cache: false
					}
		
		            $http(request).then(function (data, status, headers) {
		                var contentType = data.headers('content-type');
		                if (!contentType) {
		                    contentType = 'application/csv';
		                }
						if(contentType === 'application/txt'){
							var numElements = (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.export_datatables_list)?$rootScope.app.configuration.export_datatables_list.maxItems:10000);
							var text = $filter('translate')('global.errors.exportListToLarge', { numElements: numElements})
							DialogsFactory.error( text, $filter('translate')('DIALOGS_ERROR_MSG'));
						}else{
			                var name = data.headers('content-disposition');
			                if (name) {
			                    name = name.split(";");
			                    if (name && name.length > 1) {
			                        name = name[1].trim().split("=");
			                        if (name && name.length > 1) {
			                            name = name[1];
			                            name = name.replace(/"/g, '');
			                        } else {
			                            name = 'export_'+object_type+'.csv';
			                        }
			                    } else {
			                        name = 'export_'+object_type+'.csv';
			                    }
			                } else {
			                    name = 'export_'+object_type+'.csv';
			                }
			                var file = new Blob([data.data], {type: contentType});
			                if (window.navigator.msSaveOrOpenBlob) {
			                    try {
			                        window.navigator.msSaveOrOpenBlob(file, name);
			                    } catch (e) {
			                        console.log(e.message);
			                    }
			                } else {
			                    var linkElement = document.createElement('a');
			                    try {
			                        var url = window.URL.createObjectURL(file);
			                        linkElement.setAttribute('href', url);
			                        linkElement.setAttribute("download", name);
			                        var clickEvent = new MouseEvent("click", {
			                            "view": window,
			                            "bubbles": true,
			                            "cancelable": false
			                        });
			                        linkElement.dispatchEvent(clickEvent);
			                    } catch (ex) {
			                        console.log(ex);
			                    }
			                }
						}
		            }).catch(function(error) {
		                DialogsFactory.error( 'global.errors.exportList', $filter('translate')('DIALOGS_ERROR_MSG'));
		            });
				}
			}
        }

        this.findOne = function(object_type, id, origin) {
            var httpPromise = $http({
                url: './api/general/findOne',
                method: 'GET',
                params: { object_type: object_type, id:id, origin: origin}
            }).success(function(response) {
                return JSOG.decode(response);
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.findAll = function(object_type, origin, cache, headers) {
            var httpParameters = {
                url: './api/general/findAll',
                method: 'GET',
                params: { object_type: object_type, origin: origin}
            }

            if(cache) {
                httpParameters.cache = cache;
            }

            if(headers) {
                httpParameters.headers = headers;
            }

            var httpPromise = $http(httpParameters)
                .success(function(response) {
                    return JSOG.decode(response);
                }).error(function(error) {
                    console.log(error);
                    return error;
            });

            return httpPromise;
        }

        this.query = function (object_type, query, query_parameters) {
            var httpPromise = $http({
                url: './api/general/query',
                method: 'GET',
                params: { object_type: object_type, query: query, query_parameters: query_parameters }
            }).success(function(response) {
                response.content = JSOG.decode(response.content);
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.findAllPaginated = function(object_type, page, size, sort) {
            var httpPromise = $http({
                url: './api/general/findAllPaginated',
                method: 'GET',
                params: { object_type: object_type, page: page, size: size, sort: sort}
            }).success(function(response) {
                response.content = JSOG.decode(response.content);
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.findAllSort = function(object_type, sort) {
            var httpPromise = $http({
                url: './api/general/findAllSort',
                method: 'GET',
                params: { object_type: object_type, sort : sort}
            }).success(function(response) {
                response.content = JSOG.decode(response.content);
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.findByUrl = function(url) {
            var httpPromise = $http({
                url: url,
                method: 'GET'
            }).success(function(response) {
                if(response && response.content) {
                    response.content = JSOG.decode(response.content);
                } else if (response) {
                    response = JSOG.decode(response);
                }
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.insert = function(url, data) {
            var deferred = $q.defer();

            $http({
                url: url,
                method: 'POST',
                data: JSOG.encode(data)
            }).then(function(data) {
                if(data && data.status && (data.status == 500 || data.status == 406 || data.status == 403 || data.status == 404)){
                    deferred.reject(data);
                }else{
                    deferred.resolve(JSOG.decode(data.data));
                }
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        this.insertMultiPartData = function(url, model, files) {
            var deferred = $q.defer();

            var formData = new FormData();
            if(Array.isArray(files)){
            	_.forEach(files, function(file, index){
            		formData.append('files', file);
            	});
            }else{
            	formData.append('files', files);
            }
            formData.append('model', new Blob([JSON.stringify(model)], {
                type: "application/json"
            }));
            $http({
                method: 'POST',
                url: url,
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).success(function (data, status) {
            	deferred.resolve(data);
            }).error(function (msg, code) {
            	deferred.reject(msg);
            });

            return deferred.promise;
        }

        this.update = function(url, data) {
            var deferred = $q.defer();

            $http({
                url: url,
                method: 'PUT',
                data: JSOG.encode(data)
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        
        this.updateMultiPartData = function(url, model, files) {
            var deferred = $q.defer();
            var formData = new FormData();
            if(Array.isArray(files)){
            	_.forEach(files, function(file, index){
            		formData.append('files', file);
            	});
            }else{
            	formData.append('files', files);
            }
            if(model){
	            formData.append('model', new Blob([JSON.stringify(model)], {
	                type: "application/json"
	            }));
            }
            $http({
                url: url,
                method: 'PUT',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        this.updateRegisterEntryDocumentsProfile = function(idRegisterEntry, idOldProfile, idNewProfile) {
            var deferred = $q.defer();

            $http({
            	url: './api/reg/updateRegisterEntryDocumentsProfile',
                method: 'POST',
                params: { idRegisterEntry: idRegisterEntry, idOldProfile: idOldProfile, idNewProfile: idNewProfile}
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
       
        this.checkDelete = function(url) {
            var deferred = $q.defer();

            $http({
                url: url,
                method: 'GET',
            }).then(function(data) {
                if(data) {
                    deferred.resolve(data.data);
                } else {
                    deferred.resolve(false);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        this.delete = function(url) {
            var deferred = $q.defer();

            $http({
                url: url,
                method: 'DELETE'
            }).then(function(data) {
                deferred.resolve(data);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        this.insertOrUpdate = function(url,data){
            var httpPromise = $http({
                method: 'POST',
                url: url,
                headers: {'Content-Type': 'application/json'},
                params: data
            }).success(function(response) {
                    if(response.content) {
                        response.content = JSOG.decode(response.content);
                    } else{
                        response = JSOG.decode(response);
                    }
                    return response;
                }).error(function(error) {
                console.log(error);
                return error;
            });
            return httpPromise;
        }

        this.loadData = function(origin, object_type, search, langColumn) {
            var params = {
                origin: origin,
                object_type: object_type,
                search: search,
                page: 0,
                size:100 //numero de resultados a mostrar
            }

            if(origin == 'common' && object_type == 'User') {
                params.langColumn = Language.getActiveColumn();
            }

            var httpPromise = $http({
                url: './api/general/loadData',
                method: 'GET',
                params: params
            }).success(function(response) {
                return response;
            }).error(function(msg,code) {
                console.log(msg);
            });

            return httpPromise;
        }

        this.getUrl = function(key,version) {
            var ret = './api';

            var sKey = key.split(".");

            for(var i = 0; i < sKey.length; i++) {
                ret += '/' + sKey[i];
            }

            return ret;
        }

        this.call = function(url, method, data, params) {
            var deferred = $q.defer();

            if(!url) {
                deferred.reject('No url')
            }

            var httpParams = {
                url: url,
                method: method ? method : 'GET'
            };

            if(data) {
                httpParams.data = JSOG.encode(data);
            }

            if(params) {
                httpParams.params = params;
            }

            $http(httpParams)
                .then(function(data) {
                    deferred.resolve(JSOG.decode(data.data));
                }).catch(function(error) {
                    deferred.reject(error)
            });

            return deferred.promise;
        }

		this.countersFilterDashboard = function(type, origin, object_type, filter, aditional_filter, callOrigin) {
            var request = {
                url: './api/general/count',
                method: 'GET',
                params: {
					type: type,
                    origin: origin,
                    object_type: object_type,
                    filter: angular.fromJson(filter),
                    aditional_filter: aditional_filter,
                    callOrigin: callOrigin 
				}
            }
            var httpPromise = $http(request)
                .success(function(response) {
                    response.content = JSOG.decode(response.content);
                    return response;
                }).error(function(error) {
                    console.log(error);
            });

            return httpPromise;
        }

        this.getTranslationsForSend = function () {
            return {
                "third_relation_interested": $filter('translate')('global.literals.interested'),
                "third_relation_representative": $filter('translate')('global.literals.representative'),
                "yes": $filter('translate')('global.literals.yes'),
                "no": $filter('translate')('global.literals.no')
            };
        }
    }]);